//TODO Update the Compsec by fetching the data from database instead
export const Compsec = [
  {
    compsec_id: "1",
    compsec_number: "434753432",
    name: "Syarikat Maxsend",
    logo: "/assets/logo-black.svg",
    email: "compsec@compsec.com",
    website: "compsec.co",
    contact_number: "04-92838484",
    bank_name: "Compsec Bank",
    bank_acc_num: "23232132342",
    address: {
      address: "123 Street",
      city: "Georgetown",
      state: "Penang",
      postal: "11700",
      country: "Malaysia",
    },
    // Describe your services
    service_description:
      "We offer a full range of company secretary services in Malaysia, ranging from company registration, provision of a registered office, routine secretarial maintenance, advisory and restructuring to providing assistance at the board meeting.",
    service_type: [
      "Form a new company",
      "De-registering company",
      "Change of director",
      "Change of shareholder",
      "Issue shares",
      "Change of company name",
      "Change of company address",
      "Accounting and Auditing",
      "Company Stamp",
      "Resolution for Bank Opening",
      "PoA Power of Attorney",
      "Others",
    ],
    subscription_status: "Active",
    subscription_exp_date: "2022-12-01",
    max_clients_num: "100",
  },
];
