import { useState, useEffect } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { DocumentTextIcon } from "@heroicons/react/outline";
import { HomeIcon } from "@heroicons/react/solid";
import { useNavigate, Link } from "react-router-dom";
import { Documents } from "../../../DataSource/Documents";

// TODO: Responsive design

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const pages = [
  { name: "Dashboard", href: "/dashboard", current: false },
  { name: "View Files", href: "#", current: true },
];

const navigationInit = [
  { name: "All Documents", tag: "all", current: true },
  {
    name: "Incorporation",
    tag: "incorporation",

    current: false,
  },
  { name: "Resolution", tag: "resolution", current: false },
  {
    name: "Audit Reports",
    tag: "audit",

    current: false,
  },
  { name: "Tax Return", tag: "tax", current: false },
  { name: "CTC", tag: "ctc", current: false },
  { name: "Others", tag: "others", current: false },
];

export default function Body_ViewFiles({ selectedCompany }) {
  // Filter document based on selectedCompany
  const allDocuments = Documents.filter(
    (file) => file.company_id === selectedCompany.company_id
  ).reverse(); // reverse making it new come first

  // Filter Document based on Category
  const filterDocCategory = (categoryTag) =>
    allDocuments.filter((doc) => doc.category === categoryTag);

  // Counting the number of file of the category, return in string
  const countFiles = (categoryTag, allFileList = allDocuments) =>
    categoryTag === "all"
      ? allFileList.length
      : allFileList
          .filter((doc) => doc.category === categoryTag)
          .length.toString();

  // Initial Navigation
  const navigation = navigationInit.map((item) => ({
    ...item,
    count: countFiles(item.tag),
  }));

  // State of file list
  const [fileList, setFileList] = useState(allDocuments);

  // State of currentActiveNav
  const [activeNav, setActiveNav] = useState(navigation);

  // useEffect for change of Selected Company
  useEffect(() => {
    setFileList(allDocuments);
    setActiveNav(navigation);
  }, [selectedCompany]);

  const handleClick = (e) => {
    const target = e.currentTarget.id;

    //generate file list based on click
    const newFileList =
      target === "all" ? allDocuments : filterDocCategory(target);

    setFileList(newFileList);

    // set ActiveMenu
    const newNav =
      target === "all"
        ? navigation
        : navigation.map((item) =>
            item.tag === target
              ? { ...item, current: true }
              : { ...item, current: false }
          );

    setActiveNav(newNav);
  };

  const navigate = useNavigate();

  return (
    <>
      {/* Header */}
      <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8 pb-6 md:flex">
        <nav className="flex" aria-label="Breadcrumb">
          <ol
            role="list"
            className="bg-white rounded-md shadow px-6 flex space-x-4"
          >
            <li className="flex">
              <div className="flex items-center">
                <Link to="/dashboard" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            <span className="sr-only text-amber-500 text-gray-500" />
            {pages.map((page, index) => (
              <li key={index} className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link
                    to={page.href}
                    className={`ml-4 text-sm font-${
                      page.current ? "bold" : "medium"
                    } text-${page.current ? "amber" : "gray"}-500 ${
                      page.current ? "" : "hover:text-gray-700"
                    }`}
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            onClick={() => navigate("/dashboard/request-service")}
          >
            Request Service
          </button>
        </div>
        {/*Recent activity*/}
      </div>

      {/* Body */}
      <div className="block max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-4">
        {/*left side bar*/}
        {/*// TODO Please solve the responsive*/}
        <div className="grid-cols-1 col-span-1 mr-12">
          <div className="sm:shadow sm:rounded sm:bg-white hidden sm:block overflow-hidden">
            <nav className="space-y-0" aria-label="Sidebar">
              {activeNav.map((item, index) => (
                <div
                  key={index}
                  id={item.tag}
                  onClick={handleClick}
                  className={classNames(
                    item.current
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                    "group flex items-center px-4 py-4 text-sm font-medium cursor-pointer"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  <span className="truncate">{item.name}</span>
                  {item.count ? (
                    <span
                      className={classNames(
                        item.current
                          ? "bg-white"
                          : "bg-gray-100 text-gray-600 group-hover:bg-gray-200",
                        "ml-auto inline-block py-0.5 px-3 text-xs rounded-full"
                      )}
                    >
                      {item.count}
                    </span>
                  ) : null}
                </div>
              ))}
            </nav>
          </div>
        </div>

        {/*right file list*/}
        <div className="grid-cols-2 col-span-3">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {fileList.map((doc, index) => (
                <li key={index}>
                  <a
                    href={doc.file}
                    target="_blank"
                    className="block hover:bg-gray-50"
                  >
                    <div className="px-4 py-4 flex items-center sm:px-6">
                      <div className="flex-shrink-0 pr-5">
                        <DocumentTextIcon className="h-10 w-10 " />
                      </div>
                      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="truncate">
                          <div className="flex text-md">
                            <p className="font-medium text-gray-900 truncate">
                              {doc.name}{" "}
                              {doc.status.color === "green" ? (
                                ""
                              ) : (
                                <span
                                  className={`inline-flex rounded-full bg-${doc.status.color}-100 px-2 text-xs font-semibold leading-5 text-${doc.status.color}-800`}
                                >
                                  {doc.status.status}
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="mt-1 flex">
                            <div className="flex items-center text-sm text-gray-500">
                              <p>{doc.date}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-5 flex-shrink-0">
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
