import CompSec_Body_Dashboard from "./CompSec_Body_Dashboard";
import { Route, Routes } from "react-router-dom";
import CompSec_Body_ManageCompany from "./CompSec_Body_ManageCompany";
import CompSec_Body_Setting from "./CompSec_Body_Setting";

export default function CompSec_Body() {
  return (
    <>
      <Routes>
        <Route index element={<CompSec_Body_Dashboard />} />
        <Route path="dashboard" element={<CompSec_Body_Dashboard />} />
        <Route
          path="manage-company/*"
          element={<CompSec_Body_ManageCompany />}
        />
        <Route path="setting" element={<CompSec_Body_Setting />} />
      </Routes>
    </>
  );
}
