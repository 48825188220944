export function Body_BasicInfo_FinInfo({ selectedCompany }) {
  // The following is to calculate the financial year of the company
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const todayDate = new Date();
  const monthNow = todayDate.getMonth();
  const companyMonthIndex = month.indexOf(selectedCompany.book_closing_month);
  const year = todayDate.getFullYear();

  // Company Financial Info
  const companyFinancialInfo = [
    {
      what: "Financial Year Accounting",
      answer: `${month[companyMonthIndex]} ${
        companyMonthIndex >= monthNow ? year : year + 1
      }`,
    },
    {
      what: "Bank Detail",
      answer: selectedCompany.bank_name,
    },
    {
      what: "Bank Account Number",
      answer: selectedCompany.bank_acc_num,
    },
    {
      what: "Account Signing Condition",
      answer: selectedCompany.bank_sign_condition,
    },
    {
      what: "Auditor",
      answer: selectedCompany.auditor_name,
    },
    {
      what: "Tax Agent",
      answer: selectedCompany.tax_agent_name,
    },
  ];
  return (
    // Company Financial Info

    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 border-b-amber-400 border-b">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Financial
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Information related to Financial of the Company
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          {companyFinancialInfo.map((info, index) =>
            index % 2 > 0 ? (
              <div
                key={index}
                className="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              >
                <dt className="text-sm font-medium text-gray-500">
                  {info.what}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {info.answer}
                </dd>
              </div>
            ) : (
              <div
                key={index}
                className="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              >
                <dt className="text-sm font-medium text-gray-500">
                  {info.what}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {info.answer}
                </dd>
              </div>
            )
          )}
        </dl>
      </div>
    </div>
  );
}
