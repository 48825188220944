import ReactPaginate from "react-paginate";
import { useState, useEffect } from "react";
import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

// TODO Known Issue: the Active Page will not reset after search (if you click prev next and page number during search)
// Look for solution using forcePage here: https://github.com/AdeleD/react-paginate/issues/198 , https://github.com/AdeleD/react-paginate/issues/406

export function Body_Shareholding_Table({ selectedShareHolding, totalShares }) {
  // Maximum item in a page
  const maxItemPerPage = 10;

  // set current page start item
  const [currentStartItem, setCurrentStartItem] = useState(1);

  // set current page end item
  const [currentEndItem, setCurrentEndItem] = useState(10);

  // Count of all items for the table
  const [totalItem, setTotalItem] = useState(0);

  // Item to be shown in the current table
  const [items, setItems] = useState([]);

  // Number of pages required for all items
  const [pageCount, setPageCount] = useState(0);

  // value of search bar
  const [searchValue, setSearchValue] = useState("");

  // items based value of search bar
  const [totalSearchItems, setTotalSearchItems] = useState([]);

  /////////////////////////////////////////////////////////////////////////////

  // Generate page view based on Item Array and given Page Number, by default start with page 1

  function generatePageItems(itemArr, currPage = 1) {
    // Total number of items for the Table
    const allItemsCount = itemArr.length;

    // The Starting item number of the page
    const startItemNumber = allItemsCount === 0 ? 0 : currPage * 10 - 9;

    // The last item number of the page (CurrentEndItem)
    const endItemNumber = Math.min(currPage * 10, allItemsCount);

    // Set total page number for Pagination
    const totalPage = Math.ceil(allItemsCount / maxItemPerPage);

    // Set the State for all above
    setTotalItem(allItemsCount); // set total number
    setCurrentStartItem(startItemNumber); // set current start number
    setCurrentEndItem(endItemNumber); // set current end number
    setPageCount(totalPage); // set total page number for pagination

    // Items on the current page
    const currentPageItem = itemArr.filter(
      (_item, index) => index >= startItemNumber - 1 && index < endItemNumber
    );

    setItems(currentPageItem); // set current view items on table
  }

  // Set Table based on Selected Company
  useEffect(() => {
    generatePageItems(selectedShareHolding);
  }, [selectedShareHolding]);

  const handlePageClick = (data) => {
    // console.log(data); // {selected: 1}
    // console.log(data.selected);  // return :: the index of the page click (page 1 = 0 , page 2 = 1)

    let currentPage = data.selected + 1;

    // Checking if there is any value coming from search bar
    let itemsToGenerate =
      searchValue.length > 0 ? totalSearchItems : selectedShareHolding;

    generatePageItems(itemsToGenerate, currentPage);
  };

  // Grab the value from search bar
  function searchShareholder(value) {
    // value = input value from the search bar
    // console.log(value);

    // The array of search results
    const searchResultAll = selectedShareHolding.filter(
      (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    );

    if (value.length === 0) generatePageItems(selectedShareHolding);
    if (value.length > 0) generatePageItems(searchResultAll);

    setSearchValue(value); // update the state of value from search
    setTotalSearchItems(searchResultAll); // update the state of results from search
  }

  return (
    <>
      <div className="sm:flex sm:items-center">
        {/*Title*/}
        <div className="sm:flex-auto ml-2 ">
          <h1 className="text-xl font-semibold text-gray-900">Shareholders</h1>
          <p className="mt-2 text-sm text-gray-700">
            List of all Shareholders of the Company
          </p>
        </div>
        {/*Title END */}
        <div className="mt-4 sm:mt-0 pl-2 lg:ml-16 sm:flex-none xl:w-72 lg:w-52 md:w-72">
          <div className="flex justify-center">
            <div className="mb-3 pt-6  w-full">
              <input
                type="search"
                className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-amber-500 focus:ring-transparent focus:border-2 focus:outline-none
      "
                id="searchShareholder"
                placeholder="Search shareholder"
                onChange={(event) => searchShareholder(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 px-6">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Type
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Share
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {item.name} <br />{" "}
                        <span className={"text-gray-500 font-normal"}>
                          {" "}
                          {item.email}{" "}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium">
                        {item.is_company ? "Company" : "Individual"}
                        <br />{" "}
                        <span className={"text-gray-500 font-normal"}>
                          {" "}
                          {item.types_of_shares}{" "}
                        </span>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium">
                        {item.share_amount} <br />{" "}
                        <span className={"text-gray-500 font-normal"}>
                          {Math.round(
                            (item.share_amount / totalShares) * 10000
                          ) / 100}
                          {"%"}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/*Pagination Start*/}
              <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                <ReactPaginate
                  containerClassName={"flex-1 flex justify-between sm:hidden"}
                  previousLabel={"Previous"}
                  previousClassName={
                    "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  }
                  nextLabel={"Next"}
                  nextClassName={
                    "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  }
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  pageClassName={"hidden"}
                />
                {/*Mobile Version END*/}
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  {/*Left Text*/}
                  <div>
                    {/*Starting result*/}
                    <p className="text-sm text-gray-700">
                      Showing{" "}
                      <span className="font-medium">{currentStartItem}</span> to{" "}
                      {/*End result of that page*/}
                      <span className="font-medium">
                        {currentEndItem}
                      </span> of {/*Total Result*/}
                      <span className="font-medium">{totalItem}</span> results
                    </p>
                  </div>
                  {/*Left Text End*/}
                  {/*ReactPagination SETUP*/}
                  <ReactPaginate
                    pageCount={pageCount}
                    previousLabel={
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    }
                    previousClassName={
                      "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    }
                    nextLabel={
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    }
                    nextClassName={
                      "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    }
                    breakLabel={"..."}
                    breakLinkClassName={
                      "relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                    }
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={1}
                    containerClassName={
                      "relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    }
                    pageClassName={
                      "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                    }
                    activeClassName={
                      "z-10 bg-amber-50 border-amber-500 text-amber-600"
                    }
                    onPageChange={handlePageClick}

                    // onClick={paginationNavClick}
                  />
                </div>
              </div>
            </div>
            {/*Pagination END*/}
          </div>
        </div>
      </div>
    </>
  );
}
