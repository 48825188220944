import ReactPaginate from "react-paginate";
import { useState, useEffect } from "react";
import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  CashIcon,
  DocumentTextIcon,
  UserAddIcon,
} from "@heroicons/react/outline";
import { HomeIcon } from "@heroicons/react/solid";
import { useNavigate, Link } from "react-router-dom";
import { Activities } from "../../../DataSource/Activities";

const pages = [
  { name: "Dashboard", href: "/dashboard", current: false },
  { name: "Recent Activities", href: "#", current: true },
];

// output the icon based on provided type

function typeIcon(type) {
  let icon = <UserAddIcon className="text-gray-900" aria-hidden="true" />;
  if (type === "doc")
    icon = <DocumentTextIcon className="text-gray-900" aria-hidden="true" />;
  if (type === "bill")
    icon = <CashIcon className="text-gray-900" aria-hidden="true" />;

  return icon;
}

export default function Body_Activities({ selectedCompany }) {
  // get selected company IDs
  const selectedCompanyActivities = Activities.filter(
    (company) => company.company_id === selectedCompany.company_id
  );

  const navigate = useNavigate();

  //////////////////////////////////////////////////////////
  // Pagination

  // Maximum item in a page
  const maxItemPerPage = 15;

  // set current page start item
  const [currentStartItem, setCurrentStartItem] = useState(1);

  // set current page end item
  const [currentEndItem, setCurrentEndItem] = useState(maxItemPerPage);

  // Count of all items for the table
  const [totalItem, setTotalItem] = useState(0);

  // Item to be shown in the current table
  const [items, setItems] = useState([]);

  // Number of pages required for all items
  const [pageCount, setPageCount] = useState(0);

  // Generate page view based on Item Array and given Page Number, by default start with page 1

  function generatePageItems(itemArr, currPage = 1) {
    // Total number of items for the Table
    const allItemsCount = itemArr.length;

    // The Starting item number of the page
    const startItemNumber =
      allItemsCount === 0
        ? 0
        : currPage * maxItemPerPage - (maxItemPerPage - 1);

    // The last item number of the page (CurrentEndItem)
    const endItemNumber = Math.min(currPage * maxItemPerPage, allItemsCount);

    // Set total page number for Pagination
    const totalPage = Math.ceil(allItemsCount / maxItemPerPage);

    // Set the State for all above
    setTotalItem(allItemsCount); // set total number
    setCurrentStartItem(startItemNumber); // set current start number
    setCurrentEndItem(endItemNumber); // set current end number
    setPageCount(totalPage); // set total page number for pagination

    // Items on the current page
    const currentPageItem = itemArr.filter(
      (_item, index) => index >= startItemNumber - 1 && index < endItemNumber
    );

    setItems(currentPageItem); // set current view items on table
  }

  // Set Table based on Selected Company
  useEffect(() => {
    generatePageItems(selectedCompanyActivities);
  }, [selectedCompany]);

  const handlePageClick = (data) => {
    // console.log(data); // {selected: 1}
    // console.log(data.selected);  // return :: the index of the page click (page 1 = 0 , page 2 = 1)

    let currentPage = data.selected + 1;
    generatePageItems(selectedCompanyActivities, currentPage);
  };

  return (
    <>
      {/* Header */}
      <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8 md:flex">
        <nav className="flex" aria-label="Breadcrumb">
          <ol
            role="list"
            className="bg-white rounded-md shadow px-6 flex space-x-4"
          >
            <li className="flex">
              <div className="flex items-center">
                <Link to="/dashboard" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            <span className="sr-only text-amber-500 text-gray-500 text-green-600 hover:text-green-900 text-red-600 hover:text-red-900 text-orange-600 hover:text-orange-900" />
            {pages.map((page) => (
              <li key={page.name} className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link
                    to={page.href}
                    className={`ml-4 text-sm font-${
                      page.current ? "bold" : "medium"
                    } text-${page.current ? "amber" : "gray"}-500 ${
                      page.current ? "" : "hover:text-gray-700"
                    }`}
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            onClick={() => navigate("/dashboard/request-service")}
          >
            Request Service
          </button>
        </div>
      </div>

      {/* Activity list  */}
      {/*To genarate required css*/}
      <span className="h-5 w-5 text-gray-900 sr-only bg-green-100 bg-orange-100 bg-red-100 text-green-800 text-orange-800 text-red-800" />
      <div className="mt-6 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Description
                </th>

                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Link</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {items.map((item, index) =>
                item.type === "doc" ? (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <div className="flex items-center">
                        <div className="h-5 w-5 flex-shrink-0">
                          {typeIcon(item.type)}
                        </div>
                        <div className="ml-4 text-sm font-medium text-gray-900">
                          {item.desc}
                        </div>
                      </div>
                    </td>

                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {item.date}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        className={`inline-flex rounded-full bg-${item.status.color}-100 px-2 text-xs font-semibold leading-5 text-${item.status.color}-800`}
                      >
                        {item.status.status}
                      </span>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <a
                        href={item.link}
                        target="_blank"
                        className={`text-${item.status.color}-600 hover:text-${item.status.color}-900`}
                      >
                        View
                      </a>
                    </td>
                  </tr>
                ) : (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6">
                      <div className="flex items-center">
                        <div className="h-5 w-5 flex-shrink-0">
                          {typeIcon(item.type)}
                        </div>
                        <div className="ml-4 text-sm font-medium text-gray-900">
                          {item.desc}
                        </div>
                      </div>
                    </td>

                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {item.date}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        className={`inline-flex rounded-full bg-${item.status.color}-100 px-2 text-xs font-semibold leading-5 text-${item.status.color}-800`}
                      >
                        {item.status.status}
                      </span>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <Link
                        to={item.link}
                        className={`text-${item.status.color}-600 hover:text-${item.status.color}-900`}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          {/*Pagination*/}
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <ReactPaginate
              containerClassName={"flex-1 flex justify-between sm:hidden"}
              previousLabel={"Previous"}
              previousClassName={
                "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              }
              nextLabel={"Next"}
              nextClassName={
                "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              }
              onPageChange={handlePageClick}
              pageCount={pageCount}
              pageClassName={"hidden"}
            />
            {/*Mobile Version END*/}
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {/*Left Text*/}
              <div>
                {/*Starting result*/}
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">{currentStartItem}</span> to{" "}
                  {/*End result of that page*/}
                  <span className="font-medium">{currentEndItem}</span> of{" "}
                  {/*Total Result*/}
                  <span className="font-medium">{totalItem}</span> results
                </p>
              </div>
              {/*Left Text End*/}
              {/*ReactPagination SETUP*/}
              <ReactPaginate
                pageCount={pageCount}
                previousLabel={
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                }
                previousClassName={
                  "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                }
                nextLabel={
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                }
                nextClassName={
                  "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                }
                breakLabel={"..."}
                breakLinkClassName={
                  "relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={1}
                containerClassName={
                  "relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                }
                pageClassName={
                  "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                }
                activeClassName={
                  "z-10 bg-amber-50 border-amber-500 text-amber-600"
                }
                onPageChange={handlePageClick}

                // onClick={paginationNavClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
