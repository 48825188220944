import { useParams } from "react-router-dom";

export default function CompSec_Body_ManageCompany_Bills_Detail() {
  const { billID } = useParams();
  return (
    <>
      <h1>hello {billID}</h1>
    </>
  );
}
