// This Director_PIC is based on Companies

export const Director_PIC = [
  // Todo: generate this data with the help of user_and_company + user_designation table
  {
    id: 1,
    company: "Nexent Sdn Bhd",
    company_id: "1",
    name: "Tan Day Vid",
    designation: "Director",
    appointment_date: "2019-04-12", //yyyy-mm-dd
    email: "dayvid@nexent.co",
    contact_number: "019-222-9183",
    identification_type: "Passport", // Todo: where are we storing this data
    identification_num: "A1223459",
    user_avatar: "https://avatars.githubusercontent.com/u/1247608?v=4",
    isDirector: true,
    isPIC: true,
    address: {
      address: "12 Temple Street",
      city: "Kangar",
      state: "Perlis",
      postal: "03000",
      country: "Malaysia",
    },
  },
  {
    id: 2,
    company: "Nexent Sdn Bhd",
    company_id: "1",
    name: "Lim Ah Beng",
    designation: "Managing Director",
    appointment_date: "2018-04-12", //yyyy-mm-dd
    email: "ahbeng@nexent.co",
    contact_number: "012-765-2693",
    identification_type: "Malaysia IC",
    identification_num: "801121-28-7629",
    user_avatar: "https://avatars.githubusercontent.com/u/13970979?v=4",
    isDirector: true,
    isPIC: false,
    address: {
      address: "33 Benghock District",
      city: "Skudai",
      state: "Johor",
      postal: "837265",
      country: "Malaysia",
    },
  },
  {
    id: 3,
    company: "Nexent Sdn Bhd",
    company_id: "1",
    name: "Ooi Ah Leng",
    designation: "CMO",
    appointment_date: "2019-12-08", //yyyy-mm-dd
    email: "ahleng@nexent.co",
    contact_number: "013-355-4103",
    identification_type: "Malaysia IC",
    identification_num: "810753-45-8365",
    user_avatar: "https://avatars.githubusercontent.com/u/77413445?v=4",
    isDirector: true,
    isPIC: false,
    address: {
      address: "M-19, Black K, Niama Street",
      city: "Ipoh",
      state: "Perak",
      postal: "39875",
      country: "Malaysia",
    },
  },
  {
    id: 4,
    company: "Xpress AI",
    company_id: "2",
    name: "Edward Gonzalez",
    designation: "CEO",
    appointment_date: "2021-11-12", //yyyy-mm-dd
    email: "edward@xpress.ai",
    contact_number: "503-352-04625",
    identification_type: "Passport",
    identification_num: "J3208321321",
    user_avatar: "https://avatars.githubusercontent.com/u/122480?v=4",
    isDirector: true,
    isPIC: true,
    address: {
      address: "338, Bastard Street",
      city: "Himeji",
      state: "Kyto",
      postal: "376H73",
      country: "Japan",
    },
  },
  {
    id: 5,
    company: "Xpress AI",
    company_id: "2",
    name: "Nakamura Mamasang",
    designation: "COO",
    appointment_date: "2022-11-01", //yyyy-mm-dd
    email: "mamasang@nexent.co",
    contact_number: "466-984-2039",
    identification_type: "Passport",
    identification_num: "J8398723",
    user_avatar: "https://avatars.githubusercontent.com/u/1611939?v=4",
    isDirector: true,
    isPIC: true,
    address: {
      address: "672 Totoro Street",
      city: "Kampai",
      state: "Tokyo",
      postal: "204957",
      country: "Japan",
    },
  },
];
