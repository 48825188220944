import { useEffect, useState } from "react";

export default function CompSec_Body_ManageCompany_Company_BizAdd({ address }) {
  const [addressName, setAddName] = useState("");
  const [addressCountry, setAddCountry] = useState("");
  const [addressAdd, setAddAddress] = useState("");
  const [addressCity, setAddCity] = useState("");
  const [addressState, setAddState] = useState("");
  const [addressPostal, setAddPostal] = useState("");

  useEffect(() => {
    if (address === undefined) return;

    setAddName(address?.name);
    setAddCountry(address?.country);
    setAddAddress(address?.address);
    setAddCity(address?.city);
    setAddState(address?.state);
    setAddPostal(address?.postal);
  }, [address]);

  function handleChangeName(e) {
    setAddName(e.target.value);
  }
  function handleChangeCountry(e) {
    setAddCountry(e.target.value);
  }
  function handleChangeAddress(e) {
    setAddAddress(e.target.value);
  }
  function handleChangeCity(e) {
    setAddCity(e.target.value);
  }
  function handleChangeState(e) {
    setAddState(e.target.value);
  }
  function handleChangePostal(e) {
    setAddPostal(e.target.value);
  }

  return (
    <>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Business Address
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              The main place where business operates
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="business-address-1"
                  className="block text-sm font-medium text-gray-700"
                >
                  Location Name
                </label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={addressName}
                  onChange={handleChangeName}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                ></input>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country
                </label>
                <input
                  value={addressCountry}
                  onChange={handleChangeCountry}
                  type="text"
                  id="country"
                  name="country"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                ></input>
              </div>

              <div className="col-span-6">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Street address
                </label>
                <input
                  type="text"
                  value={addressAdd}
                  onChange={handleChangeAddress}
                  name="street-address"
                  id="street-address"
                  autoComplete="street-address"
                  className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City
                </label>
                <input
                  value={addressCity}
                  onChange={handleChangeCity}
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="address-level2"
                  className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="region"
                  className="block text-sm font-medium text-gray-700"
                >
                  State / Province
                </label>
                <input
                  value={addressState}
                  onChange={handleChangeState}
                  type="text"
                  name="region"
                  id="region"
                  autoComplete="address-level1"
                  className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium text-gray-700"
                >
                  ZIP / Postal code
                </label>
                <input
                  value={addressPostal}
                  onChange={handleChangePostal}
                  type="text"
                  name="postal-code"
                  id="postal-code"
                  autoComplete="postal-code"
                  className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
