import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Don't remove the current: true because it is used to set the default value
const navigationtabs = [
  { name: "Basic Info", href: "company", current: true },
  { name: "Structure", href: "structure", current: false },
  { name: "Shareholding", href: "shareholding", current: false },
];

export function Body_Tabs() {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    return () => {
      // the below const path will get the slug of url, example:
      // abc.com/edf => return edf ; abc.com/edf/abc/eee => return eee
      const path = window.location.pathname.split("/").pop();
      const tabIndex = navigationtabs.findIndex((tab) => tab.href == path);

      // If tabIndex can't find the path match with href, it will return -1
      if (tabIndex < 0) {
        return setActiveTab[0];
      } else setActiveTab(tabIndex);
    };
  }, []);

  const toggleTab = (index) => {
    setActiveTab(index);
  };

  const navigation = useNavigate();

  const handlechange = (e) => {
    const navToTabIndex = navigationtabs.findIndex(
      (tab) => tab.name === e.target.value
    );
    const navToTab = navigationtabs.find((tab) => tab.name === e.target.value);
    setActiveTab(navToTabIndex);
    navigation(navToTab.href);
  };

  return (
    <div className="px-4 sm:px-0">
      <div className="sm:hidden">
        <label htmlFor="Dashboard-tabs" className="sr-only">
          Select a tab
        </label>
        <select
          onChange={handlechange}
          id="company-tabs"
          className="block w-full rounded-md border-gray-300 text-base font-medium text-gray-900 shadow-sm focus:border-go-500 focus:ring-cyan-500"
          value={navigationtabs[activeTab].name}
        >
          {navigationtabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {navigationtabs.map((tab, tabIdx) => (
            <Link
              key={tab.name}
              to={tab.href}
              aria-current={activeTab === tabIdx ? "page" : undefined}
              className={classNames(
                activeTab === tabIdx
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === navigationtabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              )}
              onClick={() => toggleTab(tabIdx)}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  activeTab === tabIdx ? "bg-amber-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
}
