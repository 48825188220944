import { Header_CompanySelection } from "./Header_CompanySelection";
import { useNavigate } from "react-router-dom";

export default function Header({
  Companies,
  SelectedCompany,
  SetSelectCompany,
}) {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between ">
            {/* Company Profile */}
            <Header_CompanySelection
              Companies={Companies}
              SelectedCompany={SelectedCompany}
              SetSelectCompany={SetSelectCompany}
            />
            {/* Button on the Right of Company Name */}
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                onClick={() => navigate("/dashboard/view-files")}
              >
                View Files
              </button>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                onClick={() => navigate("/dashboard/orders")}
              >
                My Orders
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
