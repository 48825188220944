// This Billing is based on Companies

export const Billing = [
  {
    id: 1,
    invoice_num: "AX-1211",
    invoice_title: "Company Restructuring",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 500, quantity: 1 },
      { description: "bcd", unit_price: 1500, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 2000, // Number
    issuer_id: "1",
    issuer_attachment: { id: "", name: "", mime: "" }, // attachment from issuer
    payer_type: "company", // company, compsec, individual
    payer_id: "1", // id of company / individual to pay
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "", // Files
    date_issued: "2022-01-26",
    date_due: "2022-02-06",
  },
  {
    id: 2,
    invoice_num: "AX-1213",
    invoice_title: "Resolution for Bank Opening",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 100, quantity: 1 },
      { description: "bcd", unit_price: 1500, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 1600, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-02-10",
    date_due: "2022-03-10",
  },
  {
    id: 3,
    invoice_num: "AX-1214",
    invoice_title: "Share Issuing",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 300, quantity: 1 },
      { description: "bcd", unit_price: 200, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 500, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-02-18",
    date_due: "2022-03-18",
  },
  {
    id: 4,
    invoice_num: "AX-1215",
    invoice_title: "Tax Submission",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 500, quantity: 1 },
      { description: "bcd", unit_price: 1000, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 1500, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-03-01",
    date_due: "2022-04-01",
  },
  {
    id: 5,
    invoice_num: "AX-1218",
    invoice_title: "Resolution of Director",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 10, quantity: 1 },
      { description: "bcd", unit_price: 150, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 160, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-03-12",
    date_due: "2022-04-12",
  },
  {
    id: 6,
    invoice_num: "AX-1222",
    invoice_title: "Accounting Services",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 50, quantity: 1 },
      { description: "bcd", unit_price: 200, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 250, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-03-26",
    date_due: "2022-04-26",
  },
  {
    id: 7,
    invoice_num: "AX-1254",
    invoice_title: "Accounting Services",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 500, quantity: 1 },
      { description: "bcd", unit_price: 100, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 600, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-04-11",
    date_due: "2022-05-11",
  },
  {
    id: 8,
    invoice_num: "AX-1255",
    invoice_title: "Company Renewal",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 500, quantity: 1 },
      { description: "bcd", unit_price: 75, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 575, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-04-30",
    date_due: "2022-05-30",
  },
  {
    id: 9,
    invoice_num: "AX-1256",
    invoice_title: "Secretarial Support",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 500, quantity: 1 },
      { description: "bcd", unit_price: 400, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 900, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-05-15",
    date_due: "2022-06-15",
  },
  {
    id: 10,
    invoice_num: "AX-1260",
    invoice_title: "Additional Services",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
      { description: "bcd", unit_price: 4000, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 5000, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-05-22",
    date_due: "2022-06-22",
  },
  {
    id: 11,
    invoice_num: "AX-1265",
    invoice_title: "Accounting Services",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
      { description: "bcd", unit_price: 250, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 1250, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-05-29",
    date_due: "2022-06-29",
  },
  {
    id: 12,
    invoice_num: "AX-1266",
    invoice_title: "Company Renewal",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 50, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 50, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-06-12",
    date_due: "2022-07-12",
  },
  {
    id: 13,
    invoice_num: "AX-1267",
    invoice_title: "Change of Director",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 100, quantity: 1 },
      { description: "bcd", unit_price: 200, quantity: 1 },
      { description: "EFG ITB", unit_price: 300, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 600, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-06-22",
    date_due: "2022-07-22",
  },
  {
    id: 14,
    invoice_num: "AX-1268",
    invoice_title: "Additional Services",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
      { description: "bcd", unit_price: 1500, quantity: 1 },
      { description: "bcd", unit_price: 1000, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 2500, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-06-24",
    date_due: "2022-07-24",
  },
  {
    id: 15,
    invoice_num: "AX-1270",
    invoice_title: "Secretarial Support",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 100, quantity: 1 },
      { description: "bcd", unit_price: 500, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 600, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "1",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-07-12",
    date_due: "2022-08-12",
  },
  {
    id: 16,
    invoice_num: "AX-1271",
    invoice_title: "Secretarial Support",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 100, quantity: 1 },
      { description: "bcd", unit_price: 500, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 700, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "2",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-07-12",
    date_due: "2022-08-12",
  },
  {
    id: 17,
    invoice_num: "AX-1272",
    invoice_title: "Additional Services",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
      { description: "bcd", unit_price: 500, quantity: 1 },
      { description: "bcd", unit_price: 200, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 1700, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "2",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-07-18",
    date_due: "2022-08-18",
  },
  {
    id: 18,
    invoice_num: "AX-1278",
    invoice_title: "Company Renewal",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
      { description: "bcd", unit_price: 800, quantity: 1 },
      { description: "bcd", unit_price: 500, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 2300, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "2",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-07-20",
    date_due: "2022-08-20",
  },
  {
    id: 19,
    invoice_num: "AX-1279",
    invoice_title: "Secretarial Support",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
      { description: "bcd", unit_price: 2000, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 1200, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "2",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-08-01",
    date_due: "2022-09-01",
  },
  {
    id: 20,
    invoice_num: "AX-1280",
    invoice_title: "Company Restructuring",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
      { description: "bcd", unit_price: 500, quantity: 1 },
      { description: "bcd", unit_price: 500, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 2000, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "2",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-08-02",
    date_due: "2022-09-02",
  },
  {
    id: 21,
    invoice_num: "AX-1282",
    invoice_title: "Change of Shareholder",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
      { description: "bcd", unit_price: 500, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 1500, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "2",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-08-12",
    date_due: "2022-09-12",
  },
  {
    id: 22,
    invoice_num: "AX-1285",
    invoice_title: "Share Issuing",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
      { description: "bcd", unit_price: 200, quantity: 1 },
      { description: "bcd", unit_price: 400, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 1600, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "2",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-08-18",
    date_due: "2022-09-18",
  },
  {
    id: 23,
    invoice_num: "AX-1289",
    invoice_title: "Accounting Services",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 1000, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "2",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-08-22",
    date_due: "2022-09-22",
  },
  {
    id: 24,
    invoice_num: "AX-1290",
    invoice_title: "Additional Services",
    invoice_currency: "RM",
    invoice_richtext_details: [
      { description: "Item ABC", unit_price: 1000, quantity: 1 },
      { description: "bcd", unit_price: 1050, quantity: 1 },
    ],
    service_type: "",
    payment_status: { status: "Complete", color: "green" }, // Complete, Pending, Paid, Due // green, orange, red
    total_amount: 2050, // Number
    issuer_id: "1",
    issuer_attachment: "",
    payer_type: "company", // company, compsec, individual
    payer_id: "2",
    payment_info: "", // more payment details, such as bank information, address etc
    payment_link: "https://nexent.co/",
    payment_proof_client: "",
    proof_of_work: "",
    date_issued: "2022-09-02",
    date_due: "2022-10-02",
  },
];
