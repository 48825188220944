export const Subsidiaries = [ // Todo: Generate this data from company and biz_address table
  {
    // Todo: are we storing this kind of data for each business address? Is that going to be linked through 'parent_company' link?
    parent_company: "1", // the ID of parent company
    company_id: "2",
    name: "Xpress AI",
    incorp_date: "2011-09-03",
    status: { status: "Pending Audit", color: "orange" }, // green, orange, red
    reg_address: {
      address: "123 Jp Street",
      city: "Tokyo City",
      state: "Tokyo",
      postal: "22231",
      country: "Japan",
    },
    biz_address: [
      {
        name: "HQ",
        address: "123 JPY Street",
        city: "Kyoto",
        state: "Hokkaido",
        postal: "728282",
        country: "Japan",
      },
    ],
  },
];
