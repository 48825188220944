import { Body_BasicInfo_CompanyContact } from "./Body_BasicInfo_CompanyContact";
import { Body_BasicInfo_FinInfo } from "./Body_BasicInfo_FinInfo";
import { Body_BasicInfo_GovAgency } from "./Body_BasicInfo_GovAgency";

export function Body_BasicInfo({ selectedCompany }) {
  // Company Basic Information
  const companyBasicInfo = [
    {
      what: "Company Name",
      answer: selectedCompany.name,
    },
    {
      what: "Company No.",
      answer: selectedCompany.reg_number,
    },
    {
      what: "Incorporation Date",
      answer: selectedCompany.incorp_date,
    },
    {
      what: "Incorporation Type",
      answer: selectedCompany.incorp_type,
    },
    {
      what: "Paid Up Capital",
      answer: selectedCompany.paid_up_capital,
    },
  ];

  return (
    <>
      {/*// Company Information Begin*/}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 border-b-amber-400 border-b">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Basic Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Basic information about the Company
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            {companyBasicInfo.map((info, index) =>
              index % 2 > 0 ? (
                <div
                  key={index}
                  className="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt className="text-sm font-medium text-gray-500">
                    {info.what}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {info.answer}
                  </dd>
                </div>
              ) : (
                <div
                  key={index}
                  className="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt className="text-sm font-medium text-gray-500">
                    {info.what}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {info.answer}
                  </dd>
                </div>
              )
            )}
          </dl>
        </div>
      </div>

      {/*// Other Components in the BasicInfo*/}
      <Body_BasicInfo_CompanyContact selectedCompany={selectedCompany} />
      <Body_BasicInfo_FinInfo selectedCompany={selectedCompany} />
      <Body_BasicInfo_GovAgency selectedCompany={selectedCompany} />
    </>
  );
}
