// This Billing is based on Companies

export const Documents = [
  {
    id: 1,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02794.pdf", // link to the file document
    name: "Resolution to Open Bank Account",
    category: "incorporation", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-01-05",
  },
  {
    id: 2,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Resolution to remove director",
    category: "resolution", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-01-09",
  },
  {
    id: 3,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Management Account Report",
    category: "audit", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-01-20",
  },
  {
    id: 4,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "Tax Return",
    category: "tax", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-01-25",
  },
  {
    id: 5,
    status: { status: "Pending Stamping", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "CTC Company Registration",
    category: "CTC", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-02-05",
  },
  {
    id: 6,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02788", // link to the file document
    name: "Sample of Other Document",
    category: "others", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-02-10",
  },
  {
    id: 7,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02794.pdf", // link to the file document
    name: "Resolution to Open Bank Account 2",
    category: "incorporation", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-02-15",
  },
  {
    id: 8,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Resolution to remove director 2",
    category: "resolution", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-02-19",
  },
  {
    id: 9,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Management Account Report 2",
    category: "audit", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-03-01",
  },
  {
    id: 10,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "Tax Return 2",
    category: "tax", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-03-11",
  },
  {
    id: 11,
    status: { status: "Pending Stamping", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "CTC Company Registration 2",
    category: "ctc", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-03-12",
  },
  {
    id: 12,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02788", // link to the file document
    name: "Sample of Other Document 2",
    category: "others", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-03-15",
  },
  {
    id: 13,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02794.pdf", // link to the file document
    name: "Resolution to Open Bank Account 3",
    category: "incorporation", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-03-21",
  },
  {
    id: 14,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Resolution to remove director 3",
    category: "resolution", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-04-01",
  },
  {
    id: 15,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Management Account Report 3",
    category: "audit", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-04-09",
  },
  {
    id: 16,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "Tax Return 3",
    category: "tax", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-04-12",
  },
  {
    id: 17,
    status: { status: "Pending Stamping", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "CTC Company Registration 3",
    category: "ctc", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-04-15",
  },
  {
    id: 18,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02788", // link to the file document
    name: "Sample of Other Document 3",
    category: "others", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-04-18",
  },
  {
    id: 19,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02794.pdf", // link to the file document
    name: "Resolution to Open Bank Account 4",
    category: "incorporation", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-04-20",
  },
  {
    id: 20,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Resolution to remove director 4",
    category: "resolution", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-04-28",
  },
  {
    id: 21,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Management Account Report 4",
    category: "audit", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-05-02",
  },
  {
    id: 22,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "Tax Return 4",
    category: "tax", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-05-09",
  },
  {
    id: 23,
    status: { status: "Pending Stamping", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "CTC Company Registration 4",
    category: "ctc", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-05-11",
  },
  {
    id: 24,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02788", // link to the file document
    name: "Sample of Other Document 4",
    category: "others", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-05-15",
  },
  {
    id: 25,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02794.pdf", // link to the file document
    name: "Resolution to Open Bank Account 5",
    category: "incorporation", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-05-18",
  },
  {
    id: 26,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Resolution to remove director 5",
    category: "resolution", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-05-22",
  },
  {
    id: 27,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Management Account Report 5",
    category: "audit", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-05-28",
  },
  {
    id: 28,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "Tax Return 5",
    category: "tax", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-06-03",
  },
  {
    id: 29,
    status: { status: "Pending Stamping", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "CTC Company Registration 5",
    category: "ctc", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-06-08",
  },
  {
    id: 30,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02788", // link to the file document
    name: "Sample of Other Document 5",
    category: "others", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-06-10",
  },
  {
    id: 31,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02794.pdf", // link to the file document
    name: "Resolution to Open Bank Account 6",
    category: "incorporation", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-06-11",
  },
  {
    id: 32,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Resolution to remove director 7",
    category: "resolution", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-06-18",
  },
  {
    id: 33,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Management Account Report 7",
    category: "audit", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-06-24",
  },
  {
    id: 34,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "Tax Return 7",
    category: "tax", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-06-28",
  },
  {
    id: 35,
    status: { status: "Pending Stamping", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "CTC Company Registration 7",
    category: "ctc", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-07-01",
  },
  {
    id: 36,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02788", // link to the file document
    name: "Sample of Other Document 7",
    category: "others", // incorporation,resolution, audit,tax,ctc, others
    company_id: "1",
    date: "2022-07-08",
  },
  {
    id: 37,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02794.pdf", // link to the file document
    name: "Resolution to Open Bank Account",
    category: "incorporation", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-02-02",
  },
  {
    id: 38,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Resolution to remove director",
    category: "resolution", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-02-08",
  },
  {
    id: 39,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Management Account Report",
    category: "audit", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-02-15",
  },
  {
    id: 40,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "Tax Return",
    category: "tax", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-02-24",
  },
  {
    id: 41,
    status: { status: "Pending Stamping", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "CTC Company Registration",
    category: "ctc", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-03-03",
  },
  {
    id: 42,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02788", // link to the file document
    name: "Sample of Other Document",
    category: "others", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-03-13",
  },
  {
    id: 43,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02794.pdf", // link to the file document
    name: "Resolution to Open Bank Account 2",
    category: "incorporation", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-03-22",
  },
  {
    id: 44,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Resolution to remove director 2",
    category: "resolution", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-03-29",
  },
  {
    id: 45,
    status: { status: "Pending Signature", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02634.pdf", // link to the file document
    name: "Management Account Report 2",
    category: "audit", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-03-24",
  },
  {
    id: 46,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "Tax Return 2",
    category: "tax", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-04-03",
  },
  {
    id: 47,
    status: { status: "Pending Stamping", color: "orange" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02562.pdf", // link to the file document
    name: "CTC Company Registration 2",
    category: "ctc", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-04-09",
  },
  {
    id: 48,
    status: { status: "Complete", color: "green" }, // status: complete, pending signature, pending // color: green orange
    file: "https://arxiv.org/pdf/2208.02788", // link to the file document
    name: "Sample of Other Document 2",
    category: "others", // incorporation,resolution, audit,tax,ctc, others
    company_id: "2",
    date: "2022-04-21",
  },
];
