import React from "react";
import { PaperClipIcon, SwitchVerticalIcon } from "@heroicons/react/solid";
import { useState, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDropzone } from "react-dropzone";

// TODO: Need to add the stuff for proof of work, attachment there fetch from server
// TODO: when upload the payment proof, send to server to upload the record, and change the status
// TODO: Put the order status on the header
// TODO: KNOWN Issues: When change company, it will gives warning, can ignore that

export default function Body_Orders_Detail_Body({
  billDetail,
  selectedCompany,
  companySecretary,
}) {
  function fileSizeSI(a, b, c, d, e) {
    return (
      ((b = Math),
      (c = b.log),
      (d = 1e3),
      (e = (c(a) / c(d)) | 0),
      a / b.pow(d, e)).toFixed(2) +
      " " +
      (e ? "kMGTPEZY"[--e] + "B" : "Bytes")
    );
  }
  //kB,MB,GB,TB,PB,EB,ZB,YB

  // Format the Date
  const dateFormated = function (date) {
    return new Date(date).toLocaleDateString("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Modal for Make a Payment
  const [openPayment, setOpenPayment] = useState(false);
  const cancelButtonRef = useRef(null);

  // Dropzone Configuration
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 10000000, // Set file size Max of 10mb
  });
  // show list of files from Dropzone
  const files = acceptedFiles.map((file, index) => (
    <li
      key={index}
      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
    >
      <div className="w-0 flex-1 flex items-center">
        <PaperClipIcon
          className="flex-shrink-0 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <span className="ml-2 flex-1 w-0 truncate">{file.path}</span>
      </div>
      <div className="ml-4 flex-shrink-0">
        <span className="font-medium text-amber-600 hover:text-amber-500">
          {fileSizeSI(file.size)}
        </span>
      </div>
    </li>
  ));

  return (
    <>
      {/*Payment Modal*/}
      <Transition.Root show={openPayment} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenPayment}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <SwitchVerticalIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Payment Detail
                        </Dialog.Title>

                        <form className="space-y-8 ">
                          <div>
                            <p className="mt-1 text-sm text-gray-500">
                              Please make payment to the following Bank Account
                              number and upload your payment receipt to us.
                            </p>
                          </div>
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                              <div className="block text-sm font-medium text-gray-700">
                                Bank Name
                              </div>
                              <div className="mt-1">
                                <div className="block w-full">
                                  {companySecretary.bank_name}
                                </div>
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <div className="block text-sm font-medium text-gray-700">
                                Bank Account Number
                              </div>
                              <div className="mt-1">
                                <div className="mt-1">
                                  <div className="block w-full">
                                    {companySecretary.bank_acc_num}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*File Upload*/}
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                              <label
                                htmlFor="file-upload"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Proof of Payment
                              </label>

                              <span className="sr-only bg-gray-100">
                                To Generate the Background Color for Dragable
                              </span>
                              {/*File Upload - Drag Drop Area*/}
                              <div
                                id="dragdropzone"
                                {...getRootProps({
                                  className:
                                    "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md mt-2",
                                })}
                              >
                                <input {...getInputProps()} />
                                <div className="space-y-1 text-center">
                                  <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <div className="text-sm text-gray-600">
                                    <p className="pl-1">
                                      Click here to upload, or drag and drop the
                                      file here
                                    </p>
                                    <p className="text-xs text-gray-500">
                                      PNG, JPG, PDF up to 10MB
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="sm:col-span-2">
                                <dd className="mt-1 text-sm text-gray-900">
                                  {acceptedFiles.length > 0 ? (
                                    <ul
                                      role="list"
                                      className="border border-gray-200 rounded-md divide-y divide-gray-200"
                                    >
                                      {files}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </dd>
                              </div>
                            </div>
                          </div>
                          {/*TODO to complete the drag drop and file list function*/}
                          <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                          >
                            Save
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpenPayment(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/*Body*/}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8 items-center">
        <div className="bg-white shadow overflow-hidden p-3">
          {/*Header*/}
          <div className="flex-wrap md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {billDetail.invoice_title}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Order {billDetail.invoice_num}
                </p>
              </div>
            </div>
            <div className="px-6 space-x-3 md:mt-0 md:mx-4 mb-4">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                onClick={() => setOpenPayment(true)}
              >
                Make a Payment
              </button>
            </div>
          </div>
          {/*Body*/}
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Bill to</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {selectedCompany.name}
                </dd>
                <dd className="mt-1 text-sm text-gray-900">
                  {selectedCompany.biz_address[0].address},{" "}
                </dd>
                <dd className="mt-1 text-sm text-gray-900">
                  {`${selectedCompany.biz_address[0].city} ${selectedCompany.biz_address[0].postal}, ${selectedCompany.biz_address[0].state}, ${selectedCompany.biz_address[0].country}`}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Due Date</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {dateFormated(billDetail.date_due)}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Bill issued by
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {companySecretary.name}
                </dd>
                <dd className="mt-1 text-sm text-gray-900">
                  {companySecretary.address.address},{" "}
                </dd>
                <dd className="mt-1 text-sm text-gray-900">
                  {`${companySecretary.address.city} ${companySecretary.address.postal}, ${companySecretary.address.state}, ${companySecretary.address.country}`}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Date issued
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {dateFormated(billDetail.date_issued)}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dd className="mt-1 text-sm text-gray-900">
                  <table className="min-w-full divide-y divide-gray-300 shadow">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Unit Price ({billDetail.invoice_currency})
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Quantity
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Amount ({billDetail.invoice_currency})
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {billDetail.invoice_richtext_details.map(
                        (item, index) => (
                          <tr key={index}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                              {item.description}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {item.unit_price}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {item.quantity}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {item.quantity * item.unit_price}
                            </td>
                          </tr>
                        )
                      )}
                      <tr>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          Total Amount
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-medium">
                          {billDetail.total_amount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Attachments
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <ul
                    role="list"
                    className="border border-gray-200 rounded-md divide-y divide-gray-200"
                  >
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <PaperClipIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="ml-2 flex-1 w-0 truncate">
                          {billDetail.invoice_title}.pdf
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a
                          href="#"
                          className="font-medium text-amber-600 hover:text-amber-500"
                        >
                          Download
                        </a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
