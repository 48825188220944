import { useParams, useNavigate } from "react-router-dom";
import { CompSec_Shareholdings } from "../../../DataSource/CompSec/CompSec_Shareholdings";
import { useEffect, useState } from "react";

export default function CompSec_Body_ManageCompany_Shareholders_Detail({
  selectedCompany,
}) {
  const { shareholderID } = useParams();
  const navigate = useNavigate();

  const [SelectedShareHolder] = CompSec_Shareholdings.filter(
    (people) =>
      people.company_id === selectedCompany.company_id &&
      people.id === Number(shareholderID)
  );

  // Set State and useEffect, Handle change for the form
  const [isCompany, setIsCompany] = useState(false);
  const [effDate, setEffDate] = useState("");
  const [fullName, setFullName] = useState("");
  const [idenNum, setIdenNum] = useState("");
  const [email, setEmail] = useState("");
  const [corpRep, setCorpRep] = useState("");
  const [authRep, setAuthRep] = useState("");
  const [shareAmt, setShareAmt] = useState("");
  const [shareType, setShareType] = useState("");

  const setSelectedShareHolderState = () => {
    setIsCompany(SelectedShareHolder?.is_company);
    setEffDate(SelectedShareHolder?.effective_date);
    setFullName(SelectedShareHolder?.name);
    setIdenNum(SelectedShareHolder?.identification_num);
    setEmail(SelectedShareHolder?.email);
    setCorpRep(SelectedShareHolder?.company_corp_rep);
    setAuthRep(SelectedShareHolder?.company_auth_rep);
    setShareAmt(SelectedShareHolder?.share_amount);
    setShareType(SelectedShareHolder?.types_of_shares);
  };

  useEffect(() => {
    // this is to make sure if user access undefined stuff, then back to previous page
    if (SelectedShareHolder?.length < 1) navigate(-1);
    if (SelectedShareHolder === undefined && shareholderID !== "new")
      navigate(-1);
    if (SelectedShareHolder?.length > 0) setSelectedShareHolderState();
    if (SelectedShareHolder === undefined) return;

    setSelectedShareHolderState();
  }, [selectedCompany, shareholderID]);

  const handleChangeIsCompany = () => setIsCompany(!isCompany);
  const handleChangeEffDate = (e) => setEffDate(e.target.value);
  const handleChangeFullName = (e) => setFullName(e.target.value);
  const handleChangeIdenNum = (e) => setIdenNum(e.target.value);
  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangeCorpRep = (e) => setCorpRep(e.target.value);
  const handleChangeAuthRep = (e) => setAuthRep(e.target.value);
  const handleChangeShareAmt = (e) => setShareAmt(e.target.value);
  const handleChangeShareType = (e) => setShareType(e.target.value);

  return (
    <>
      <div className="max-w-6xl justify-center mt-8 mx-auto px-4 sm:px-6 lg:px-10 pb-6 md:flex">
        <form className="space-y-6" action="#" method="POST">
          <button
            type="button"
            className="px-10 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            onClick={() =>
              navigate("/company-secretary/manage-company/shareholders/")
            }
          >
            Back
          </button>
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              {/*Page Title*/}
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Shareholder Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {shareholderID === "new"
                    ? "Add new shareholder to"
                    : "Update the Shareholder Information for the Company of"}{" "}
                  {selectedCompany.name}
                </p>
              </div>

              {/*Page Body*/}
              <div className="my-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <fieldset className="col-span-6 sm:col-span-3 pt-2">
                    <legend className="sr-only">
                      If the shareholder is Company or Individual
                    </legend>
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          checked={isCompany}
                          onChange={handleChangeIsCompany}
                          id="isDirector"
                          aria-describedby="director"
                          name="isPIC"
                          type="checkbox"
                          className="focus:ring-amber-500 h-4 w-4 text-amber-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="isDirector"
                          className="font-medium text-gray-700"
                        >
                          The shareholder is a company
                        </label>
                        <p
                          id="candidates-description"
                          className="text-gray-500"
                        >
                          The shareholder is a registered company
                        </p>
                      </div>
                    </div>
                  </fieldset>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="effective-date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Effective date
                    </label>
                    <input
                      value={effDate}
                      onChange={handleChangeEffDate}
                      type="date"
                      name="effective-date"
                      id="effective-date"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Full name
                    </label>
                    <input
                      type="text"
                      value={fullName}
                      onChange={handleChangeFullName}
                      name="name"
                      id="name"
                      autoComplete="given-name"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="identification_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Identification Number
                    </label>
                    <input
                      value={idenNum}
                      onChange={handleChangeIdenNum}
                      type="text"
                      name="identification_num"
                      id="identification_num"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={handleChangeEmail}
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  {/*for Corp only, corp rep and auth rep*/}
                  {isCompany ? (
                    <>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="company_corp_rep"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Corporate Representative
                        </label>
                        <input
                          type="text"
                          name="company_corp_rep"
                          id="company_corp_rep"
                          value={corpRep}
                          onChange={handleChangeCorpRep}
                          autoComplete="family-name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="company_auth_rep"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Appointment Date
                        </label>
                        <input
                          type="text"
                          value={authRep}
                          onChange={handleChangeAuthRep}
                          name="company_auth_rep"
                          id="company_auth_rep"
                          autoComplete="family-name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {/*Share detail*/}
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="share_amount"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Designation
                    </label>
                    <input
                      type="number"
                      name="share_amount"
                      id="share_amount"
                      value={shareAmt}
                      onChange={handleChangeShareAmt}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="types_of_shares"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Shares Type
                    </label>
                    <input
                      type="text"
                      value={shareType}
                      onChange={handleChangeShareType}
                      name="types_of_shares"
                      id="types_of_shares"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Submit or reset button*/}
          <div className="flex justify-end">
            {shareholderID !== "new" ? (
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete
              </button>
            ) : (
              ""
            )}

            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Submit Changes
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
