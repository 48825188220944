// Activities from:
// type: doc, bill, shareholders, director_pic
// description: describe what's done above
// date: due date/ effective date/ appointed date
// status:  status of doc
//
// documents status:
// - desc: doc title
// - date: date
// - status: status
//
// billing
// - desc : bill title
// - date: due date
// - status: status
//
// update: shareholders
// - desc: Shareholder Update: $shareholder_name
// - date: effective date
// - status: green complete
// - link -> link to shareholdings
//
// update: pic / director
// - desc: PIC / Director Update: $pic/director_name
// - date: appointment date
// - status: green complete
// - link -> link to structure

import { Documents } from "./Documents";
import { Billing } from "./Billing";
import { Director_PIC } from "./Director_PIC";
import { Shareholding } from "./Shareholding";

// Format the Date
const dateFormated = function (date) {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

// convert the given input to a proper activity object
function activityFormat(
  company_id,
  type,
  desc,
  status = { status: "Complete", color: "green" },
  date,
  link
) {
  return {
    company_id: company_id,
    type: type, // doc, bill, shareholders, director_pic
    desc: desc,
    status: status,
    date: dateFormated(date),
    link: link,
  };
}

// Convert doc, bill, shareholders, director_pic to proper format

const docActivities = Documents.map((doc) =>
  activityFormat(
    doc.company_id,
    "doc",
    doc.name,
    doc.status,
    doc.date,
    doc.file
  )
);

const billingActivities = Billing.filter(
  (bill) => bill.payer_type === "company"
).map((bill) =>
  activityFormat(
    bill.payer_id,
    "bill",
    `${bill.invoice_num} ${bill.invoice_title}`,
    bill.payment_status,
    bill.date_due,
    `/dashboard/orders/${bill.id}`
  )
);
// filter above is to filter bill to company

const directorPICActivities = Director_PIC.map((user) =>
  activityFormat(
    user.company_id,
    "director_pic",
    `${user.isDirector ? "Director" : ""} ${
      user.isDirector && user.isPIC ? "& " : ""
    }${user.isPIC ? "Person in Charge (PIC)" : ""} update: added ${user.name}`,
    { status: "Complete", color: "green" },
    user.appointment_date,
    "/dashboard/structure"
  )
);

const shareHoldingsActivities = Shareholding.map((shareholder) =>
  activityFormat(
    shareholder.company_id,
    "shareholders",
    `Shareholder list update: added ${shareholder.name}`,
    { status: "Complete", color: "green" },
    shareholder.effective_date,
    "/dashboard/shareholding"
  )
);

const allActivity = docActivities
  .concat(billingActivities)
  .concat(directorPICActivities)
  .concat(shareHoldingsActivities)
  .sort((a, b) => new Date(b.date) - new Date(a.date));
// activities sorted from latest to oldest

// prioritize the pending items
export const pendingActivities = allActivity.filter(
  (item) => item.status.color !== "green"
);

const completeActivities = allActivity.filter(
  (item) => item.status.color === "green"
);

const pendingFirstActivities = pendingActivities.concat(completeActivities);

// replace the constan here depend on how you want to see on the activities page:
// to show all activities without prioritize pending => allActivity
// to show only pending activity => pendingActivities
// to show activities prioritizing pending => pendingFirstActivities
export const Activities = pendingFirstActivities;
