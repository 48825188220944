import { useState, useEffect } from "react";
import { CompSec_Director_PIC } from "../../../DataSource/CompSec/CompSec_Director_PIC";
import PICDirect from "./CompSec_Body_ManageCompany_PIC_Director_Element";

//TODO: Refactor all these stupid code, add a modal to alert reset / submit for changes to double confirm the action

export default function CompSec_Body_ManageCompany_PIC_Director({
  selectedCompany,
}) {
  // the Initial People Element Array based on selected Company
  const selectedDirectorPICInit = CompSec_Director_PIC.filter(
    (item) => item.company_id === selectedCompany.company_id
  ).map((people, index) => (
    <PICDirect people={people} companyName={selectedCompany.name} key={index} />
  ));

  // Setting the State for people element Array
  const [peopleArr, setPeopleArr] = useState([]);

  useEffect(() => setPeopleArr(selectedDirectorPICInit), [selectedCompany]);

  const handleClickAddPeople = () => {
    const key = peopleArr.length;
    const combinePeopleEl = peopleArr.concat(<PICDirect key={key} />);
    setPeopleArr(combinePeopleEl);
  };

  const handleClickDeletePeople = () => {
    if (peopleArr <= 1) return;
    let newPeopleArr = peopleArr.slice(0, -1);
    setPeopleArr(newPeopleArr);
  };

  const handleClickReset = () => {
    setPeopleArr([]);
    setTimeout(() => setPeopleArr(selectedDirectorPICInit), 1);
  };

  return (
    <>
      <div className="max-w-6xl mx-auto mt-8 px-4 sm:px-6 lg:px-10 pb-6 md:flex">
        <form className="space-y-6" action="#" method="POST">
          {/*People Element*/}
          {peopleArr}

          {/*Button Add Delete People*/}
          <div className="flex justify-start space-x-4 -mt-20 -pt-20">
            <button
              onClick={() => handleClickDeletePeople()}
              type="button"
              className=" bg-red-600 py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-100 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Delete person
            </button>
            <button
              type="button"
              className=" bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
              onClick={() => handleClickAddPeople()}
            >
              Add another person
            </button>
          </div>

          {/*Submit or reset button*/}
          <div className="flex justify-end">
            <button
              onClick={() => handleClickReset()}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Reset
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Submit Changes
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
