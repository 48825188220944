export function Body_BasicInfo_CompanyContact({ selectedCompany }) {
  // Company Contact

  // TODO: The biz address is hardcoded as for now, need to make it
  const companyBizAddress = selectedCompany.biz_address[0];

  const companyContact = [
    {
      what: "Contact Number",
      answer: selectedCompany.contact_number,
    },
    {
      what: "Registered Address",
      answer: `${selectedCompany.reg_address.address}, ${selectedCompany.reg_address.city}, ${selectedCompany.reg_address.state} ${selectedCompany.reg_address.postal}, ${selectedCompany.reg_address.country}`,
    },
    {
      what: "Business Address",
      answer: `${companyBizAddress.address}, ${companyBizAddress.city}, ${companyBizAddress.state} ${companyBizAddress.postal}, ${companyBizAddress.country}`,
    },
  ];

  return (
    // Company Contact

    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 border-b-amber-400 border-b">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Contact</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Primary Contact Information of the Company
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          {companyContact.map((info, index) =>
            index % 2 > 0 ? (
              <div
                key={index}
                className="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              >
                <dt className="text-sm font-medium text-gray-500">
                  {info.what}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {info.answer}
                </dd>
              </div>
            ) : (
              <div
                key={index}
                className="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              >
                <dt className="text-sm font-medium text-gray-500">
                  {info.what}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {info.answer}
                </dd>
              </div>
            )
          )}
        </dl>
      </div>
    </div>
  );
}
