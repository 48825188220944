import { NavLink } from "react-router-dom";
import { CompSec_Body_ManageCompany_Header_CompanySelection } from "./CompSec_Body_ManageCompany_Header_CompanySelection";

const headerNav = [
  { name: "Company", href: "/company-secretary/manage-company/company" },
  {
    name: "PIC & Director",
    href: "/company-secretary/manage-company/pic-director",
  },
  {
    name: "Shareholders",
    href: "/company-secretary/manage-company/shareholders",
  },
  { name: "Files", href: "/company-secretary/manage-company/files" },
  { name: "Bills", href: "/company-secretary/manage-company/bills" },
];

export default function CompSec_Body_ManageCompany_Header({
  Companies,
  SelectedCompany,
  SetSelectCompany,
}) {
  return (
    <>
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="py-4 md:flex md:items-center md:justify-between ">
            {/* Company Profile */}
            <CompSec_Body_ManageCompany_Header_CompanySelection
              Companies={Companies}
              SelectedCompany={SelectedCompany}
              SetSelectCompany={SetSelectCompany}
            />
            {/* Button on the Right of Company Name */}
            <div className="mt-6 flex-wrap md:mt-0 md:ml-4">
              {headerNav.map((nav, index) => (
                <NavLink
                  to={nav.href}
                  key={index}
                  type="button"
                  className={({ isActive }) =>
                    isActive
                      ? "inline-flex mr-2 my-1 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                      : "inline-flex mr-2 my-1 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                  }
                >
                  {nav.name}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
