import * as React from "react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  HomeIcon,
} from "@heroicons/react/solid";
import { useNavigate, Link } from "react-router-dom";
import { Billing } from "../../../DataSource/Billing";

const pages = [
  { name: "Dashboard", href: "/dashboard", current: false },
  { name: "Orders", href: "#", current: true },
];

// TODO Need to implement the pagination on the table, 15 per page, and sort function

export default function Body_Orders({ selectedCompany }) {
  const bills = Billing.filter(
    (bill) => bill.payer_id === selectedCompany.company_id
  );

  const navigate = useNavigate();
  return (
    <>
      {/* Header */}

      <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8 pb-6 md:flex">
        <nav className="flex" aria-label="Breadcrumb">
          <ol
            role="list"
            className="bg-white rounded-md shadow px-6 flex space-x-4"
          >
            <li className="flex">
              <div className="flex items-center">
                <Link to="/dashboard" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            <span className="sr-only text-amber-500 text-gray-500" />
            {pages.map((page) => (
              <li key={page.name} className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link
                    to={page.href}
                    className={`ml-4 text-sm font-${
                      page.current ? "bold" : "medium"
                    } text-${page.current ? "amber" : "gray"}-500 ${
                      page.current ? "" : "hover:text-gray-700"
                    }`}
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            onClick={() => navigate("/dashboard/request-service")}
          >
            Request Service
          </button>
        </div>
        {/*Recent activity*/}
      </div>

      {/* Oder list (for mobile version only) */}
      <span className="sr-only text-green-800 text-orange-800 text-red-800 bg-green-100 bg-orange-100 bg-red-100">
        Generate Classlist for Bills
      </span>
      <div className="shadow sm:hidden">
        <ul
          role="list"
          className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
        >
          {bills.map((bill, index) => (
            <li key={index}>
              <Link
                to={"#"}
                className="block px-4 py-4 bg-white hover:bg-gray-50"
              >
                <span className="flex items-center space-x-4">
                  <span className="flex-1 flex space-x-2 truncate">
                    <span className="flex flex-col text-gray-500 text-sm truncate">
                      <span className="truncate">{bill.invoice_num} </span>
                      <span>
                        <span className="text-gray-900 font-medium">
                          {bill.invoice_title}
                        </span>{" "}
                        {`${
                          bill.invoice_currency
                        } ${bill.total_amount.toLocaleString("en-us")}`}
                        <span
                          className={`ml-3 inline-flex rounded-full bg-${bill.payment_status.color}-100 px-2 text-xs font-semibold leading-5 text-${bill.payment_status.color}-800`}
                        >
                          {bill.payment_status.status}
                        </span>
                      </span>
                      <time dateTime={bill.date_issued}>
                        {bill.date_issued}
                      </time>
                    </span>
                  </span>
                  <ChevronRightIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Link>
            </li>
          ))}
        </ul>

        <nav
          className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
          aria-label="Pagination"
        >
          <div className="flex-1 flex justify-between">
            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
            >
              Previous
            </a>
            <a
              href="#"
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
            >
              Next
            </a>
          </div>
        </nav>
      </div>

      {/* Order table (for Desktop) */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 sm:block hidden">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 inline-flex"
                      >
                        {" "}
                        Issued On{" "}
                        <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300 cursor-pointer">
                          <ChevronDownIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 inline-flex"
                      >
                        Price{" "}
                        <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300 cursor-pointer">
                          <ChevronDownIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {bills.map((bill, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="font-medium text-gray-900 flex-shrink">
                              {bill.invoice_num}
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {bill.invoice_title}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {bill.date_issued}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span
                            className={`inline-flex rounded-full bg-${bill.payment_status.color}-100 px-2 text-xs font-semibold leading-5 text-${bill.payment_status.color}-800`}
                          >
                            {bill.payment_status.status}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {`${
                            bill.invoice_currency
                          } ${bill.total_amount.toLocaleString("en-us")}`}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Link
                            to={`${bill.id}`}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            View
                            <span className="sr-only">Bills</span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
