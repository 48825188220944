import { CompSec_Shareholdings } from "../../../DataSource/CompSec/CompSec_Shareholdings";
import React from "react";
import { CompSec_Body_ManageCompany_Shareholders_Table } from "./CompSec_Body_ManageCompany_Shareholders_Table";
import { useNavigate } from "react-router-dom";

export default function CompSec_Body_ManageCompany_Shareholders({
  selectedCompany,
}) {
  // Filter the Shareholders for the Selected Company
  const navigate = useNavigate();

  const selectedShareHolding = CompSec_Shareholdings.filter(
    (shareholder) => shareholder.company_id === selectedCompany.company_id
  );

  // Total share of the select company
  const totalShares = selectedShareHolding
    .map((e) => e.share_amount)
    .reduce((acc, curr) => acc + curr, 0);

  return (
    <>
      <div className="max-w-6xl mx-auto mt-8 px-4 sm:px-6 lg:px-10 pb-6">
        <CompSec_Body_ManageCompany_Shareholders_Table
          selectedShareHolding={selectedShareHolding}
          totalShares={totalShares}
        />
        <div className="my-5">
          <button
            onClick={() =>
              navigate("/company-secretary/manage-company/shareholders/new")
            }
            type="button"
            className="px-10 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            Add new shareholder
          </button>
        </div>
      </div>
    </>
  );
}
