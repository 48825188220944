import { CompSec_Companies } from "../../../DataSource/CompSec/CompSec_Companies";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import CompSec_Body_ManageCompany_Header from "./CompSec_Body_ManageCompany_Header";
import CompSec_Body_ManageCompany_Company from "./CompSec_Body_ManageCompany_Company";
import CompSec_Body_ManageCompany_PIC_Director from "./CompSec_Body_ManageCompany_PIC_Director";
import CompSec_Body_ManageCompany_Shareholders from "./CompSec_Body_ManageCompany_Shareholders";
import CompSec_Body_ManageCompany_Files from "./CompSec_Body_ManageCompany_Files";
import CompSec_Body_ManageCompany_Bills from "./CompSec_Body_ManageCompany_Bills";
import CompSec_Body_ManageCompany_Bills_Detail from "./CompSec_Body_ManageCompany_Bills_Detail";
import CompSec_Body_ManageCompany_Files_Detail from "./CompSec_Body_ManageCompany_Files_Detail";
import CompSec_Body_ManageCompany_Shareholders_Detail from "./CompSec_Body_ManageCompany_Shareholders_Detail";

export default function CompSec_Body_ManageCompany() {
  const [selectedCompany, setSelectedCompany] = useState(CompSec_Companies[0]);

  return (
    <>
      <CompSec_Body_ManageCompany_Header
        SelectedCompany={selectedCompany}
        Companies={CompSec_Companies}
        SetSelectCompany={(company) => setSelectedCompany(company)}
      />

      <Routes>
        <Route
          index
          element={
            <CompSec_Body_ManageCompany_Company
              selectedCompany={selectedCompany}
            />
          }
        />
        <Route
          path="/company"
          element={
            <CompSec_Body_ManageCompany_Company
              selectedCompany={selectedCompany}
            />
          }
        />
        <Route
          path="/pic-director"
          element={
            <CompSec_Body_ManageCompany_PIC_Director
              selectedCompany={selectedCompany}
            />
          }
        />
        <Route
          path="/shareholders"
          element={
            <CompSec_Body_ManageCompany_Shareholders
              selectedCompany={selectedCompany}
            />
          }
        />
        <Route
          path="/shareholders/:shareholderID"
          element={
            <CompSec_Body_ManageCompany_Shareholders_Detail
              selectedCompany={selectedCompany}
            />
          }
        />
        <Route
          path="/files"
          element={
            <CompSec_Body_ManageCompany_Files
              selectedCompany={selectedCompany}
            />
          }
        />
        <Route
          path="/files/:fileID"
          element={
            <CompSec_Body_ManageCompany_Files_Detail
              selectedCompany={selectedCompany}
            />
          }
        />
        <Route
          path="/bills"
          element={
            <CompSec_Body_ManageCompany_Bills
              selectedCompany={selectedCompany}
            />
          }
        />
        <Route
          path="/bills/:billID"
          element={
            <CompSec_Body_ManageCompany_Bills_Detail
              selectedCompany={selectedCompany}
            />
          }
        />
      </Routes>
    </>
  );
}
