import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { NavigationMobile } from "../../Components/Navigation";
import { MenuAlt1Icon, XIcon } from "@heroicons/react/outline";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Body from "./Components/Body";
import Body_Activities from "./Components/Body_Activities";
import Body_RequestService from "./Components/Body_RequestService";
import Body_ViewFiles from "./Components/Body_ViewFiles";
import Body_Orders from "./Components/Body_Orders";
import OrderDetail from "./Components/Body_Orders_Detail";

export default function Dashboard({ LoginUser, Companies, Compsecs }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(Companies[0]);

  return (
    <>
      {/*Sidebar for Mobile*/}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-cyan-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="/assets/logo-full-white.svg"
                      alt="Nexent Backoffice"
                    />
                  </div>
                  <nav
                    className="mt-5 flex-shrink-0 h-full divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <NavigationMobile />
                  </nav>
                </div>
                <div className="flex-shrink-0 flex border-t border-cyan-800 px-2 pt-4">
                  <a href="#" className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src={LoginUser.user_avatar}
                          alt={LoginUser.name}
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-white">
                          {LoginUser.name}
                        </p>
                        <p className="text-sm font-medium text-cyan-200 group-hover:text-white">
                          View profile
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="lg:pl-64 flex flex-col flex-1">
        {/*Mobile Menu*/}
        <div className="sticky top-0 z-10 lg:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white shadow">
          {/*Button to open sidebar for Mobile*/}
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {/*The Body of the Dashboard*/}
        <main className="flex-1 pb-8">
          {/* Page header */}
          <Header
            SelectedCompany={selectedCompany}
            Companies={Companies}
            SetSelectCompany={(company) => setSelectedCompany(company)}
          />
          {/* Page body */}
          <div className="mt-8">
            <Routes>
              <Route
                path="/*"
                element={
                  <Body
                    selectedCompany={selectedCompany}
                    companySecretary={Compsecs[0]}
                  />
                }
              />
              {/*Other pages will go here in the body part*/}

              <Route
                path="/request-service"
                element={
                  <Body_RequestService
                    selectedCompany={selectedCompany}
                    companySecretary={Compsecs[0]}
                    LoginUser={LoginUser}
                  />
                }
              />

              <Route
                path="/activities"
                element={<Body_Activities selectedCompany={selectedCompany} />}
              />
              <Route
                path="/view-files"
                element={<Body_ViewFiles selectedCompany={selectedCompany} />}
              />
              <Route
                path="/orders"
                element={<Body_Orders selectedCompany={selectedCompany} />}
              />
              <Route
                path="/orders/:orderID"
                element={<OrderDetail selectedCompany={selectedCompany} />}
              />
            </Routes>
          </div>
        </main>
      </div>
    </>
  );
}
