import { useState, useEffect } from "react";

export default function CompSec_Body_ManageCompany_PIC_Director_Element({
  people,
  companyName,
}) {
  const [fullName, setFullName] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [appDate, setAppDate] = useState("");
  const [idenType, setIdenType] = useState("");
  const [idenNum, setIdenNum] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postal, setPostal] = useState("");
  const [isPIC, setIsPIC] = useState(false);
  const [isDirector, setIsDirector] = useState(false);

  useEffect(() => {
    if (people === undefined) return;
    setFullName(people?.name);
    setDesignation(people?.designation);
    setEmail(people?.email);
    setAppDate(people?.appointment_date);
    setIdenType(people?.identification_type);
    setIdenNum(people?.identification_num);
    setCountry(people?.address.country);
    setAddress(people?.address.address);
    setCity(people?.address.city);
    setState(people?.address.state);
    setPostal(people?.address.postal);
    setIsPIC(people?.isPIC);
    setIsDirector(people?.isDirector);
  }, [people]);

  const handleChangeFullName = (e) => setFullName(e.target.value);
  const handleChangeDesignation = (e) => setDesignation(e.target.value);
  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangeAppDate = (e) => setAppDate(e.target.value);
  const handleChangeIdenType = (e) => setIdenType(e.target.value);
  const handleChangeIdenNum = (e) => setIdenNum(e.target.value);
  const handleChangeCountry = (e) => setCountry(e.target.value);
  const handleChangeAddress = (e) => setAddress(e.target.value);
  const handleChangeCity = (e) => setCity(e.target.value);
  const handleChangeState = (e) => setState(e.target.value);
  const handleChangePostal = (e) => setPostal(e.target.value);
  const handleChangeIsPIC = (e) => setIsPIC(!isPIC);
  const handleChangeIsDirector = (e) => setIsDirector(!isDirector);

  return (
    <>
      {/*Title of the Box*/}
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Person Information
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Person in charge and/or Director of the {companyName}
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full name
                </label>
                <input
                  type="text"
                  value={fullName}
                  onChange={handleChangeFullName}
                  name="name"
                  id="name"
                  autoComplete="given-name"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="designation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Designation
                </label>
                <input
                  type="text"
                  name="designation"
                  id="designation"
                  value={designation}
                  onChange={handleChangeDesignation}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={handleChangeEmail}
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="appointment_date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Appointment Date
                </label>
                <input
                  type="date"
                  value={appDate}
                  onChange={handleChangeAppDate}
                  name="appointment_date"
                  id="appointment_date"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="identification_type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Identification type
                </label>
                <select
                  value={idenType}
                  onChange={handleChangeIdenType}
                  id="identification_type"
                  name="identification_type"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option>Identification Card (IC)</option>
                  <option>Passport</option>
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="identification_num"
                  className="block text-sm font-medium text-gray-700"
                >
                  Identification Number
                </label>
                <input
                  value={idenNum}
                  onChange={handleChangeIdenNum}
                  type="text"
                  name="identification_num"
                  id="identification_num"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country
                </label>
                <input
                  type="text"
                  value={country}
                  onChange={handleChangeCountry}
                  id="country"
                  name="country"
                  autoComplete="country-name"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="col-span-6">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Street address
                </label>
                <input
                  type="text"
                  value={address}
                  onChange={handleChangeAddress}
                  name="street-address"
                  id="street-address"
                  autoComplete="street-address"
                  className="mt-1 focus:ring-indigo-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City
                </label>
                <input
                  value={city}
                  onChange={handleChangeCity}
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="address-level2"
                  className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="region"
                  className="block text-sm font-medium text-gray-700"
                >
                  State / Province
                </label>
                <input
                  value={state}
                  onChange={handleChangeState}
                  type="text"
                  name="region"
                  id="region"
                  autoComplete="address-level1"
                  className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium text-gray-700"
                >
                  ZIP / Postal code
                </label>
                <input
                  type="text"
                  value={postal}
                  onChange={handleChangePostal}
                  name="postal-code"
                  id="postal-code"
                  autoComplete="postal-code"
                  className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <fieldset className="space-y-5 mt-5">
              <legend className="sr-only">
                Is the person Director and/or PIC
              </legend>
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    checked={isPIC}
                    onChange={handleChangeIsPIC}
                    id="isPIC"
                    aria-describedby="isPIC"
                    name="isPIC"
                    type="checkbox"
                    className="focus:ring-amber-500 h-4 w-4 text-amber-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="isPIC" className="font-medium text-gray-700">
                    Person in charge (PIC)
                  </label>
                  <p id="isPIC-description" className="text-gray-500">
                    The main contact point of {companyName}
                  </p>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    checked={isDirector}
                    onChange={handleChangeIsDirector}
                    id="isDirector"
                    aria-describedby="isDirector"
                    name="isDirector"
                    type="checkbox"
                    className="focus:ring-amber-500 h-4 w-4 text-amber-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="isDirector"
                    className="font-medium text-gray-700"
                  >
                    Director
                  </label>
                  <p id="isDirector-description" className="text-gray-500">
                    {fullName} is the Director of {companyName}?
                  </p>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </>
  );
}
