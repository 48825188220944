import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CompSec_Documents } from "../../../DataSource/CompSec/CompSec_Documents";

export default function CompSec_Body_ManageCompany_Files_Detail({
  selectedCompany,
}) {
  const { fileID } = useParams();

  const [theFile] = CompSec_Documents.filter(
    (file) =>
      file.id === Number(fileID) &&
      file.company_id === selectedCompany.company_id
  );

  // Set Company Status Color
  const initStatusColor = [
    { status: "Good", color: "green", checked: theFile.status.color },
    {
      status: "Moderate",
      color: "orange",
      checked: theFile.status.color,
    },
    { status: "Bad", color: "red", checked: theFile.status.color },
  ];

  // useState for Status
  const [statusColor, setStatusColor] = useState([]);
  const [statusStatus, setStatusStatus] = useState("");

  // regenerate the status based on changed of company
  useEffect(() => {
    const statusColor = initStatusColor.map((stat, index) => (
      <div className="flex items-center" key={index}>
        <input
          id={stat.color}
          name="status-color"
          type="radio"
          checked={stat.color === stat.checked}
          onChange={handleChangeStatus}
          className={`focus:ring-${stat.color}-500 h-5 w-5 text-${stat.color}-600 border-gray-300`}
        />
        <label
          htmlFor={`status-${stat.status}`}
          className="ml-3 block text-sm font-medium text-gray-700"
        >
          {stat.status}
        </label>
      </div>
    ));

    setStatusColor(statusColor);
    setStatusStatus(theFile.status.status);
  }, [selectedCompany]);

  // HandleChange for all status conditions
  const handleChangeStatusStatus = (e) => setStatusStatus(e.target.value);
  const handleChangeStatus = (e) => {
    const statusColor = initStatusColor.map((stat, index) => (
      <div className="flex items-center" key={index}>
        <input
          id={stat.color}
          name="status-color"
          type="radio"
          checked={stat.color === e.target.id}
          onChange={handleChangeStatus}
          className={`focus:ring-${stat.color}-500 h-5 w-5 text-${stat.color}-600 border-gray-300`}
        />
        <label
          htmlFor={`status-${stat.status}`}
          className="ml-3 block text-sm font-medium text-gray-700"
        >
          {stat.status}
        </label>
      </div>
    ));
    setStatusColor(statusColor);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="max-w-6xl justify-center mx-auto mt-8 px-4 sm:px-6 lg:px-10 pb-6 md:flex">
        <form className="space-y-6" action="#" method="POST">
          {/*Company Status*/}
          <button
            type="button"
            className="px-10 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            onClick={() =>
              navigate("/company-secretary/manage-company/shareholders/")
            }
          >
            Back
          </button>

          {/*Status*/}
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Status
                </h3>
                <p className="mt-1 text-sm text-gray-500">Status of the file</p>
              </div>
              <div className="mt-5 space-y-6 md:mt-0 md:col-span-2">
                <div>
                  <fieldset className="mt-4">
                    <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                      {statusColor}
                    </div>
                  </fieldset>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="status-status"
                      className="block text-sm font-medium text-gray-700"
                    >
                      What's the status of the company?
                    </label>
                    <input
                      type="text"
                      name="status-status"
                      id="status-status"
                      value={statusStatus}
                      onChange={handleChangeStatusStatus}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Basic Info*/}
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  File information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  This is all about your file
                </p>
              </div>
              <div className="mt-5 space-y-6 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      File name
                    </label>
                    <input
                      type="text"
                      name="company-name"
                      id="company-name"
                      // onChange={handleChangeCompanyName}
                      // value={companyName}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-registration-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      File Category
                    </label>
                    <input
                      type="text"
                      // value={regNum}
                      // onChange={handleChangeRegNum}
                      name="company-registration-number"
                      id="company-registration-number"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Uploaded Date
                    </label>
                    <input
                      type="date"
                      name="Incorporation-date"
                      id="Incorporation-date"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-registration-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Incorporation type
                    </label>
                    <select
                      type="text"
                      name="company-incorp-type"
                      id="company-incorp-type"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      // value={incorpType}
                      // onChange={handleChangeIncorpType}
                    >
                      <option>Sole proprietorship</option>
                      <option>Partnership</option>
                      <option>Limited Liability Partnership (LLP)</option>
                      <option>Sendirian Berhad (Sdn Bhd)</option>
                      <option>Berhad (Bhd)</option>
                      <option>Other</option>
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Paid up capital
                    </label>
                    <input
                      type="text"
                      name="Incorporation-date"
                      id="Incorporation-date"
                      // onChange={handleChangePaidUpCap}
                      // value={paidUpCap}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Fiscal Year
                    </label>
                    <select
                      type="text"
                      name="fiscal-year"
                      id="book_closing_month"
                      // value={fiscalYear}
                      // onChange={handleChangeFiscalYear}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    >
                      {/*{month.map((month, index) => (*/}
                      {/*  <option key={index}>{month}</option>*/}
                      {/*))}*/}
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Website
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        http://
                      </span>
                      <input
                        type="text"
                        // value={compWebsite}
                        // onChange={handleChangeCompWebsite}
                        name="website"
                        id="website"
                        className="focus:ring-amber-500 focus:border-amber-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                        placeholder="example.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Company Icon (max 2MB)
                      </label>
                      <div className="mt-1 flex items-center space-x-5">
                        <img
                          // src={companyIcon}
                          // alt={companyName}
                          className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100"
                        />

                        <button
                          type="button"
                          className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                          // onClick={handleClickIconUpload}
                        >
                          Change
                          <input
                            id="icon-upload"
                            // ref={inputRef}
                            // onChange={handleChangeIconUpload}
                            type="file"
                            accept="image/*"
                            className="sr-only"
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="company-registration-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Industry
                    </label>
                    <input
                      type="text"
                      // defaultValue={compIndustry}
                      // onChange={handleChangeCompIndustry}
                      name="company-industry"
                      id="company-industry"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company description
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows={4}
                      className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="Company A is aim to ..."
                      // value={compDesc}
                      // onChange={handleChangeCompDesc}
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Brief description for the company
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Reset
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Submit Changes
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
