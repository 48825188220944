// This Shareholdings is based on Companies
// Todo: get directly from the shareholder table

export const Shareholding = [
  {
    id: 1, //shareholder id
    name: "Skymind Holdings Berhad", // company name from company id
    email: "investor@skymind.global", //email
    identification_num: "G-1897851", // id or company id
    is_company: true,
    company_corp_rep: "Tan Ah Kau",
    company_auth_rep: "Teow Lan Sheng",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "1", // company that the shareholder has shares in
    types_of_shares: "Ordinary shares",
    share_amount: 2000,
    effective_date: "2019-05-20", //YYYY-MM-DD
  },
  {
    id: 2, //shareholder id
    name: "Shams UL Azeem", // company name from company id
    email: "shams@ul.azeem", //email
    identification_num: "P654968454", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "1",
    shareholder_company_id: "",
    company_id: "1", // company that the shareholder has shares in
    types_of_shares: "Non-voting shares",
    share_amount: 500,
    effective_date: "2021-03-11", //YYYY-MM-DD
  },
  {
    id: 3, //shareholder id
    name: "Evangeline Cheng", // company name from company id
    email: "evangeline@nexent.co", //email
    identification_num: "851122-14-5888", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "3",
    shareholder_company_id: "",
    company_id: "1", // company that the shareholder has shares in
    types_of_shares: "Preference shares",
    share_amount: 1000,
    effective_date: "2022-12-14", //YYYY-MM-DD
  },
  {
    id: 4, //shareholder id
    name: "Edward Gonzalez", // company name from company id
    email: "eduardo@xpress.ai", //email
    identification_num: "J3208321321", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Non-voting shares",
    share_amount: 5000,
    effective_date: "2022-12-12", //YYYY-MM-DD
  },
  {
    id: 5, //shareholder id
    name: "Nexent Sdn Bhd", // company name from company id
    email: "invest@nexent.co", //email
    identification_num: "9871239872", // id or company id
    is_company: true,
    company_corp_rep: "Mandy Wang",
    company_auth_rep: "Prune Quah",
    shareholder_user_id: "",
    shareholder_company_id: "1",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Preference shares",
    share_amount: 6000,
    effective_date: "2019-03-13",
  },
  {
    id: 6, //shareholder id
    name: "Leodardo Dicarpeow", // company name from company id
    email: "leo@nardo.com", //email
    identification_num: "A5684851", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Redeemable shares",
    share_amount: 100,
    effective_date: "2019-10-13",
  },
  {
    id: 7, //shareholder id
    name: "Machester Bobby", // company name from company id
    email: "man@investor.com", //email
    identification_num: "A56416581", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Non-voting shares",
    share_amount: 200,
    effective_date: "2018-05-12",
  },
  {
    id: 8, //shareholder id
    name: "Saw Hai", // company name from company id
    email: "sawhai@orang.tu", //email
    identification_num: "A56846581", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Redeemable shares",
    share_amount: 100,
    effective_date: "2021-04-23",
  },
  {
    id: 9, //shareholder id
    name: "Buskin Baakin", // company name from company id
    email: "leo@nardo.com", //email
    identification_num: "A5684851", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Non-voting shares",
    share_amount: 100,
    effective_date: "2019-03-13",
  },
  {
    id: 10, //shareholder id
    name: "Tan Leng Leng", // company name from company id
    email: "lengleng@tan.com", //email
    identification_num: "881054-15-1251", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Ordinary shares",
    share_amount: 500,
    effective_date: "2019-03-13",
  },
  {
    id: 11, //shareholder id
    name: "Chan Ah Leek", // company name from company id
    email: "chan@ah.leek", //email
    identification_num: "A5415154", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Preference shares",
    share_amount: 100,
    effective_date: "2019-03-13",
  },
  {
    id: 12, //shareholder id
    name: "Ma Ma Chan", // company name from company id
    email: "mama@investing.you", //email
    identification_num: "S5648415", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Ordinary shares",
    share_amount: 100,
    effective_date: "2019-03-20",
  },
  {
    id: 13, //shareholder id
    name: "Chua Pai Pai", // company name from company id
    email: "chuapp@investing.you", //email
    identification_num: "S56584851", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Redeemable shares",
    share_amount: 300,
    effective_date: "2019-08-13",
  },
  {
    id: 14, //shareholder id
    name: "Shawn Tu Lan", // company name from company id
    email: "shtulan@investing.you", //email
    identification_num: "G5648965101", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Preference shares",
    share_amount: 400,
    effective_date: "2019-08-13",
  },
  {
    id: 15, //shareholder id
    name: "Ew Woo Lynn", // company name from company id
    email: "wle@investing.you", //email
    identification_num: "8485168-51-5215", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Non-voting shares",
    share_amount: 150,
    effective_date: "2019-10-13",
  },
  {
    id: 16, //shareholder id
    name: "Jannice Neoh", // company name from company id
    email: "neoh@investing.you", //email
    identification_num: "H752A84SA", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Preference shares",
    share_amount: 300,
    effective_date: "2019-11-12",
  },
  {
    id: 17, //shareholder id
    name: "Huang Seh Mui", // company name from company id
    email: "hsm@investing.you", //email
    identification_num: "G4548A564", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Ordinary shares",
    share_amount: 100,
    effective_date: "2019-11-09",
  },
  {
    id: 18, //shareholder id
    name: "Peter Pan", // company name from company id
    email: "ppp@investing.you", //email
    identification_num: "S65AS892121", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Redeemable shares",
    share_amount: 100,
    effective_date: "2019-11-11",
  },
  {
    id: 19, //shareholder id
    name: "Mang Kuang", // company name from company id
    email: "mk@investing.you", //email
    identification_num: "SAS565415", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Preference shares",
    share_amount: 100,
    effective_date: "2017-02-13",
  },
  {
    id: 20, //shareholder id
    name: "Ill Nan", // company name from company id
    email: "nanan@investing.you", //email
    identification_num: "A8451515", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Non-voting shares",
    share_amount: 100,
    effective_date: "2019-05-23",
  },
  {
    id: 21, //shareholder id
    name: "Notting Ham", // company name from company id
    email: "nh@investing.you", //email
    identification_num: "841851-515-515", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Preference shares",
    share_amount: 100,
    effective_date: "2019-05-23",
  },
  {
    id: 22, //shareholder id
    name: "Guo Lily", // company name from company id
    email: "lili@investing.you", //email
    identification_num: "84851-864581", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Non-voting shares",
    share_amount: 100,
    effective_date: "2019-05-23",
  },
  {
    id: 23, //shareholder id
    name: "Baby Care", // company name from company id
    email: "bbcare@investing.you", //email
    identification_num: "AS65481850", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Ordinary shares",
    share_amount: 100,
    effective_date: "2019-05-23",
  },
  {
    id: 24, //shareholder id
    name: "Muhamand Chan", // company name from company id
    email: "Muhamand@investing.you", //email
    identification_num: "SS891581", // id or company id
    is_company: false,
    company_corp_rep: "",
    company_auth_rep: "",
    shareholder_user_id: "",
    shareholder_company_id: "",
    company_id: "2", // company that the shareholder has shares in
    types_of_shares: "Preference shares",
    share_amount: 100,
    effective_date: "2019-05-23",
  },
];
