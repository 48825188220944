import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { Body_Shareholding_Table } from "./Body_Shareholding_Table";
import { Shareholding } from "../../../DataSource/Shareholding";

//TODO Added the Chart from Chartjs here

export function Body_Shareholding({ selectedCompany }) {
  /* From Shams
    const [selectedShareHolding, selectShareHolding] = useState([]);
    const [totalShares, setTotalShares] = useState(0);


    useEffect(() => {
        if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
            // Todo: implement all of the rest APIs below:

                /api/v1/shareholdings


            fetch('/api/v1/shareholdings')
                .then(response => response.json())
                .then(shareholdings => {
                    // Filter the Shareholders for the Selected Company
                    selectShareHolding(shareholdings.filter(
                        (shareholder) => shareholder.company_id === selectedCompany.company_id
                    ));

                    // Total share of the select company
                    setTotalShares(selectedShareHolding
                        .map((e) => e.share_amount)
                        .reduce((acc, curr) => acc + curr, 0));
                })
                .catch(error => {
                    console.log("Unable to retrieve login user data", error.message);
                });
      } else {
          // Filter the Shareholders for the Selected Company
          selectShareHolding(Shareholding.filter(
              (shareholder) => shareholder.company_id === selectedCompany.company_id
          ));
      }
    }, []);



    useEffect(() => {
      setTotalShares(
        selectedShareHolding
          .map((e) => e.share_amount)
          .reduce((acc, curr) => acc + curr, 0)
      );
    }, [selectedShareHolding]);
  */

  // Filter the Shareholders for the Selected Company
  const selectedShareHolding = Shareholding.filter(
    (shareholder) => shareholder.company_id === selectedCompany.company_id
  );

  // Total share of the select company
  const totalShares = selectedShareHolding
    .map((e) => e.share_amount)
    .reduce((acc, curr) => acc + curr, 0);

  // Filter Shareholders is Company
  const selectCompany = selectedShareHolding.filter(
    (shareholder) => shareholder.is_company === true
  );

  // Filter Shareholders is Individual
  const selectIndividual = selectedShareHolding.filter(
    (shareholder) => shareholder.is_company === false
  );

  // Top 5 Individual / Company Shareholders
  const filterValue = 5;
  const topFive = selectedShareHolding
    .sort((a, b) => b.share_amount - a.share_amount)
    .filter((_obj, index) => index < filterValue);

  // Registered ChartJS
  ChartJS.register(ArcElement, Tooltip, Legend);

  // Charts For Individual vs Company
  const individualCompanyData = {
    labels: ["Company", "Individual"],
    datasets: [
      {
        label: "# of Shareholding",
        data: [selectCompany.length, selectIndividual.length],
        backgroundColor: ["rgba(245, 158, 11, 1)", "rgba(51, 65, 85, 1)"],
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label;
              let value = context.formattedValue;
              const index = context.dataIndex;
              let data = context.dataset.data[index];

              if (!label) label = "Unknown";

              let sum = 0;
              let dataArr = context.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += Number(data);
              });

              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return label + ": " + data + " (" + percentage + ")";
            },
          },
        },
      },
    ],
  };

  // Chart Options
  let chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "middle",
      },
    },
  };

  const uniqueShareType = [
    ...new Set(
      selectedShareHolding.map((sharehoder) => sharehoder.types_of_shares)
    ),
  ];

  const uniqeShareAmt = uniqueShareType.map((shareType) => ({
    type: shareType,
    amount: selectedShareHolding.filter(
      (shareholder) => shareholder.types_of_shares === shareType
    ).length,
  }));

  console.log(uniqeShareAmt);

  return selectedShareHolding ? (
    <>
      {/* Individual or Company Type */}
      <div className="sm:flex-auto ml-2">
        <h3 className="text-lg font-semibold text-gray-900">
          Shareholders: Individual vs Company
        </h3>
        <div className="px-24 bg-transparent">
          <Doughnut data={individualCompanyData} options={chartOptions} />
        </div>
      </div>
      <Body_Shareholding_Table
        selectedShareHolding={selectedShareHolding}
        totalShares={totalShares}
      />
    </>
  ) : (
    <div></div>
  );
}
