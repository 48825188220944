import { Director_PIC } from "../../../DataSource/Director_PIC";
import { Subsidiaries } from "../../../DataSource/Subsidiaries";

export function Body_Structure({ selectedCompany }) {
  // Filter the PIC based on selectedCompany
  const picCompany = Director_PIC.filter(
    (list) => list.company_id === selectedCompany.company_id && list.isPIC
  );

  // Filter the Director based on selectedCompany
  const directorList = Director_PIC.filter(
    (list) => list.company_id === selectedCompany.company_id && list.isDirector
  );

  const subsidiaries = Subsidiaries.filter(
    (subsidiary) => subsidiary.parent_company === selectedCompany.company_id
  );

  // may not have subsidiary => subsidiaries = [];

  return (
    <>
      <div className="sm:flex-auto ml-2">
        <h3 className="text-lg font-semibold text-gray-900">
          Person In Charge (PIC)
        </h3>
      </div>
      <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Contact Number
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Date Appointed
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {picCompany.map((picCompany, index) => (
              <tr key={index}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {picCompany.name}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {picCompany.contact_number}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {picCompany.email}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {picCompany.appointment_date}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="sm:flex-auto ml-2">
        <h3 className="text-lg font-semibold text-gray-900">Director</h3>
      </div>
      <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Designation
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Date Appointed
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {directorList.map((director, index) => (
              <tr key={index}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {director.name}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {director.designation}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {director.email}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {director.appointment_date}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span className="hidden sr-only"></span>
      <div
        className={`sm:flex-auto ${subsidiaries.length ? "" : "hidden"} ml-2`}
      >
        <h3 className="text-lg font-semibold text-gray-900">Subsidiaries</h3>
      </div>
      <span className="sr-only text-green-400 text-orange-400 text-red-400"></span>
      <div
        className={`-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg ${
          subsidiaries.length ? "" : "hidden"
        }`}
      >
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Status
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Location
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Date Incorporation
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {subsidiaries.map((subsidiary, index) => (
              <tr key={index}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {subsidiary.name}
                </td>

                <td
                  className={`hidden whitespace-nowrap px-3 py-4 text-sm text-${subsidiary.status.color}-400 sm:table-cell`}
                >
                  {subsidiary.status.status}
                </td>

                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell flex items-center">
                  {subsidiary.reg_address.state},{" "}
                  {subsidiary.reg_address.country}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {subsidiary.incorp_date}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
