import { MenuAlt1Icon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { NavigationMobile } from "../../Components/Navigation";
import CompSec_Body from "./Components/CompSec_Body";

import { Route, Routes, NavLink } from "react-router-dom";

const topNav = [
  {
    name: "Dashboard",
    href: "/company-secretary/dashboard",
  },
  {
    name: "Manage Company",
    href: "/company-secretary/manage-company",
  },
  {
    name: "Setting",
    href: "/company-secretary/setting",
  },
];

export default function CompSec({ LoginUser, CompSec }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      {/*Sidebar for Mobile*/}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-cyan-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="/assets/logo-full-white.svg"
                      alt="Nexent Backoffice"
                    />
                  </div>
                  <nav
                    className="mt-5 flex-shrink-0 h-full divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <NavigationMobile />
                  </nav>
                </div>
                <div className="flex-shrink-0 flex border-t border-cyan-800 px-2 pt-4">
                  <a href="#" className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src={LoginUser.user_avatar}
                          alt={LoginUser.name}
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-white">
                          {LoginUser.name}
                        </p>
                        <p className="text-sm font-medium text-cyan-200 group-hover:text-white">
                          View profile
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="lg:pl-64 flex flex-col flex-1">
        {/*Mobile Menu + Top Nav*/}
        <Disclosure as="nav" className="bg-white shadow sticky">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative flex h-12 justify-between">
                  {/*button to open mobile menu, only show at mobile size*/}
                  <div className="relative z-10 flex items-center">
                    <div className="block lg:hidden w-auto">
                      {/*Button to open sidebar for Mobile*/}
                      <button
                        type="button"
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
                        onClick={() => setSidebarOpen(true)}
                      >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  {/*Top Menu*/}
                  <div className="flex flex-1 justify-center z-0">
                    <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                      {/* Current: "border-cyan-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                      {topNav.map((item, index) => (
                        <NavLink
                          key={index}
                          to={item.href}
                          className={({ isActive }) =>
                            isActive
                              ? "border-amber-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                          }
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>

                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                {/*Mobile version Menu TopNav*/}
                <div className="pt-2 pb-3 space-y-1">
                  {/* Current: "bg-cyan-50 border-cyan-500 text-cyan-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                  {topNav.map((item, index) => (
                    <NavLink
                      key={index}
                      to={item.href}
                      className={({ isActive }) =>
                        isActive
                          ? "bg-amber-50 border-amber-500 text-cyan-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                          : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      }
                    >
                      <Disclosure.Button as="a">{item.name}</Disclosure.Button>
                    </NavLink>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/*The Body of the Company Secreatary*/}
        <main className="flex-1 pb-8">
          {/* Company Secretary Body Content */}
          <Routes>
            <Route path="/*" element={<CompSec_Body />} />
          </Routes>
        </main>
      </div>
    </>
  );
}
