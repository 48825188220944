import { useState, useEffect, useRef } from "react";
import CompSec_Body_ManageCompany_Company_BizAdd from "./CompSec_Body_ManageCompany_Company_BizAdd";

//TODO: Refactor all these stupid code, add a modal to alert reset / submit for changes to double confirm the action

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function CompSec_Body_ManageCompany_Company({
  selectedCompany,
}) {
  // TODO for Shams Challenge - refactor if you can do it in one hour
  // useState for all the form input value // so when edit and change company, everything change
  const [company, setCompany] = useState({}); // will see if required later
  const [companyName, setCompanyName] = useState("");
  const [regNum, setRegNum] = useState("");
  const [contactNum, setContactNum] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [incorpDate, setIncorpDate] = useState("");
  const [incorpType, setIncorpType] = useState("");
  const [paidUpCap, setPaidUpCap] = useState("");
  const [fiscalYear, setFiscalYear] = useState("");
  const [compWebsite, setCompWebsite] = useState("");
  const [companyIcon, setCompanyIcon] = useState("");
  const [compIndustry, setCompIndustry] = useState("");
  const [compDesc, setCompDesc] = useState("");
  const [compBankName, setCompBankName] = useState("");
  const [bankAccNum, setBankAccNum] = useState("");
  const [bankSignCon, setBankSignCon] = useState("");
  const [auditorName, setAuditorName] = useState("");
  const [auditorLink, setAuditorLink] = useState("");
  const [taxAgent, setTaxAgent] = useState("");
  const [taxAgentLink, setTaxAgentLink] = useState("");
  const [salesTax, setSalesTax] = useState("");
  const [empIden, setEmpIden] = useState("");
  const [incomeTax, setIncomeTax] = useState("");
  const [kwspNum, setKwspNum] = useState("");
  const [mofNum, setMofNum] = useState("");
  const [mitiNum, setMitiNum] = useState("");
  const [mbppNum, setMbppNum] = useState("");
  const [dbklNum, setDbklNum] = useState("");
  const [matradeNum, setMatradeNum] = useState("");
  const [dunsNum, setDunsNum] = useState("");
  const [regAddressCountry, setRegAddressCountry] = useState("");
  const [regAddressAdd, setRegAddressAdd] = useState("");
  const [regAddressCity, setRegAddressCity] = useState("");
  const [regAddressState, setRegAddressState] = useState("");
  const [regAddressPostal, setRegAddressPostal] = useState("");

  function init() {
    setCompany(selectedCompany);
    setCompanyName(selectedCompany.name);
    setRegNum(selectedCompany.reg_number);
    setContactNum(selectedCompany.contact_number);
    setCompanyEmail(selectedCompany.email);
    setIncorpDate(selectedCompany.incorp_date);
    setStatusColor(selectedCompany.status.color);
    setIncorpType(selectedCompany.incorp_type);
    setPaidUpCap(selectedCompany.paid_up_capital);
    setFiscalYear(selectedCompany.book_closing_month);
    setCompWebsite(selectedCompany.website);
    setCompanyIcon(selectedCompany.comp_logo);
    setCompIndustry(selectedCompany.industry);
    setCompDesc(selectedCompany.description);
    setCompBankName(selectedCompany.bank_name);
    setBankAccNum(selectedCompany.bank_acc_num);
    setBankSignCon(selectedCompany.bank_sign_condition);
    setAuditorName(selectedCompany.auditor_name);
    setAuditorLink(selectedCompany.auditor_link);
    setTaxAgent(selectedCompany.tax_agent_name);
    setTaxAgentLink(selectedCompany.tax_agent_link);
    setSalesTax(selectedCompany.sales_tax_num);
    setEmpIden(selectedCompany.employer_iden_num);
    setIncomeTax(selectedCompany.income_tax_num);
    setKwspNum(selectedCompany.my_kwsp_num);
    setMofNum(selectedCompany.my_mof_num);
    setMitiNum(selectedCompany.my_miti_num);
    setMbppNum(selectedCompany.my_mbpp_num);
    setDbklNum(selectedCompany.my_dbkl_num);
    setMatradeNum(selectedCompany.my_matrade_num);
    setDunsNum(selectedCompany.duns_num);
    setRegAddressCountry(selectedCompany.reg_address.country);
    setRegAddressAdd(selectedCompany.reg_address.address);
    setRegAddressCity(selectedCompany.reg_address.city);
    setRegAddressState(selectedCompany.reg_address.state);
    setRegAddressPostal(selectedCompany.reg_address.postal);
  }

  useEffect(() => {
    init();
  }, [selectedCompany]);

  // Handling the changes for all items in the form
  const handleChangeIncorpType = (e) => setIncorpType(e.target.value);
  const handleChangeFiscalYear = (e) => setFiscalYear(e.target.value);
  const handleChangeCompanyName = (e) => setCompanyName(e.target.value);
  const handleChangeRegNum = (e) => setRegNum(e.target.value);
  const handleChangeContactNum = (e) => setContactNum(e.target.value);
  const handleChangeCompanyEmail = (e) => setCompanyEmail(e.target.value);
  const handleChangeIncorpDate = (e) => setIncorpDate(e.target.value);
  const handleChangePaidUpCap = (e) => setPaidUpCap(e.target.value);
  const handleChangeCompWebsite = (e) => setCompWebsite(e.target.value);
  const handleChangeCompIndustry = (e) => setCompIndustry(e.target.value);
  const handleChangeCompDesc = (e) => setCompDesc(e.target.value);
  const handleChangeBankName = (e) => setCompBankName(e.target.value);
  const handleChangeBankAccNum = (e) => setBankAccNum(e.target.value);
  const handleChangeBankSignCon = (e) => setBankSignCon(e.target.value);
  const handleChangeAuditorName = (e) => setAuditorName(e.target.value);
  const handleChangeAuditorLink = (e) => setAuditorLink(e.target.value);
  const handleChangeTaxAgent = (e) => setTaxAgent(e.target.value);
  const handleChangeTaxAgentLink = (e) => setTaxAgentLink(e.target.value);
  const handleChangeSalesTax = (e) => setSalesTax(e.target.value);
  const handleChangeEmpIden = (e) => setEmpIden(e.target.value);
  const handleChangeIncomeTax = (e) => setIncomeTax(e.target.value);
  const handleChangeKwspNum = (e) => setKwspNum(e.target.value);
  const handleChangeMofNum = (e) => setMofNum(e.target.value);
  const handleChangeMitiNum = (e) => setMitiNum(e.target.value);
  const handleChangeMbppNum = (e) => setMbppNum(e.target.value);
  const handleChangeDbklNum = (e) => setDbklNum(e.target.value);
  const handleChangeMatradeNum = (e) => setMatradeNum(e.target.value);
  const handleChangeDunsNum = (e) => setDunsNum(e.target.value);
  const handleChangeRegAddressCountry = (e) =>
    setRegAddressCountry(e.target.value);
  const handleChangeRegAddressAdd = (e) => setRegAddressAdd(e.target.value);
  const handleChangeRegAddressCity = (e) => setRegAddressCity(e.target.value);
  const handleChangeRegAddressState = (e) => setRegAddressState(e.target.value);
  const handleChangeRegAddressPostal = (e) =>
    setRegAddressPostal(e.target.value);

  // Set Company Status Color
  const initStatusColor = [
    { status: "Good", color: "green", checked: selectedCompany.status.color },
    {
      status: "Moderate",
      color: "orange",
      checked: selectedCompany.status.color,
    },
    { status: "Bad", color: "red", checked: selectedCompany.status.color },
  ];

  // useState for Status
  const [statusColor, setStatusColor] = useState([]);
  const [statusStatus, setStatusStatus] = useState("");

  // regenerate the status based on changed of company
  useEffect(() => {
    const statusColor = initStatusColor.map((stat, index) => (
      <div className="flex items-center" key={index}>
        <input
          id={stat.color}
          name="status-color"
          type="radio"
          checked={stat.color === stat.checked}
          onChange={handleChangeStatus}
          className={`focus:ring-${stat.color}-500 h-5 w-5 text-${stat.color}-600 border-gray-300`}
        />
        <label
          htmlFor={`status-${stat.status}`}
          className="ml-3 block text-sm font-medium text-gray-700"
        >
          {stat.status}
        </label>
      </div>
    ));

    setStatusColor(statusColor);
    setStatusStatus(selectedCompany.status.status);
  }, [selectedCompany]);

  // HandleChange for all status conditions
  const handleChangeStatusStatus = (e) => setStatusStatus(e.target.value);
  const handleChangeStatus = (e) => {
    const statusColor = initStatusColor.map((stat, index) => (
      <div className="flex items-center" key={index}>
        <input
          id={stat.color}
          name="status-color"
          type="radio"
          checked={stat.color === e.target.id}
          onChange={handleChangeStatus}
          className={`focus:ring-${stat.color}-500 h-5 w-5 text-${stat.color}-600 border-gray-300`}
        />
        <label
          htmlFor={`status-${stat.status}`}
          className="ml-3 block text-sm font-medium text-gray-700"
        >
          {stat.status}
        </label>
      </div>
    ));
    setStatusColor(statusColor);
  };

  // Handling the file / image input, to upload company logo/ icon
  const inputRef = useRef();
  const handleClickIconUpload = () => inputRef.current.click();
  const handleChangeIconUpload = (event) => {
    // return the most non-falsify value
    const fileObj = event.target.files && event.target.files[0];

    if (!fileObj) {
      return;
    }

    if (fileObj.size > 2000000) return alert("The image file is too large");

    const objURL = URL.createObjectURL(fileObj);
    setCompanyIcon(objURL);

    // console.log(objURL);
    // console.log("fileObj is", fileObj);

    // reset file input
    event.target.value = null;

    // is now empty
    // console.log(event.target.files);

    // can still access file object here
    // console.log(fileObj);
    // console.log(fileObj.name);
  };

  ////////////////////////////
  // The business address - unlimited loop

  const initBizElement = selectedCompany.biz_address?.map((address, index) => (
    <CompSec_Body_ManageCompany_Company_BizAdd address={address} key={index} />
  ));

  const [bizElement, setBizElement] = useState([]);

  useEffect(() => {
    setBizElement(initBizElement);
  }, [selectedCompany]);

  const handleClickAddBizAddress = () => {
    const key = bizElement.length + 1;

    const bizCombineElement = bizElement.concat(
      <CompSec_Body_ManageCompany_Company_BizAdd key={key} />
    );
    setBizElement(bizCombineElement);
  };

  const handleClickDeleteBizAddress = () => {
    if (bizElement.length <= 1) return;
    let newBizElement = bizElement.slice(0, -1);
    setBizElement(newBizElement);
  };

  // other to be reset
  function reset() {
    init();

    // Reset Biz Elements

    setBizElement([]);

    // use set time out to reset because ... without it, it still has memory of previous change on the data
    setTimeout(() => setBizElement(initBizElement), 1);

    // reset status
    const statusColor = initStatusColor.map((stat, index) => (
      <div className="flex items-center" key={index}>
        <input
          id={stat.color}
          name="status-color"
          type="radio"
          checked={stat.color === stat.checked}
          onChange={handleChangeStatus}
          className={`focus:ring-${stat.color}-500 h-5 w-5 text-${stat.color}-600 border-gray-300`}
        />
        <label
          htmlFor={`status-${stat.status}`}
          className="ml-3 block text-sm font-medium text-gray-700"
        >
          {stat.status}
        </label>
      </div>
    ));

    setStatusColor(statusColor);
    setStatusStatus(selectedCompany.status.status);
  }

  return (
    <>
      <div className="max-w-6xl mx-auto mt-8 px-4 sm:px-6 lg:px-10 pb-6 md:flex">
        <form className="space-y-6" action="#" method="POST">
          {/*Company Status*/}
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Status
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Status of the company
                </p>
              </div>
              <div className="mt-5 space-y-6 md:mt-0 md:col-span-2">
                <div>
                  <fieldset className="mt-4">
                    <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                      {statusColor}
                    </div>
                  </fieldset>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="status-status"
                      className="block text-sm font-medium text-gray-700"
                    >
                      What's the status of the company?
                    </label>
                    <input
                      type="text"
                      name="status-status"
                      id="status-status"
                      value={statusStatus}
                      onChange={handleChangeStatusStatus}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Basic Info*/}
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Basic Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Basic information about the Company.
                </p>
              </div>
              <div className="mt-5 space-y-6 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company name
                    </label>
                    <input
                      type="text"
                      name="company-name"
                      id="company-name"
                      onChange={handleChangeCompanyName}
                      value={companyName}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-registration-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Registration number
                    </label>
                    <input
                      type="text"
                      value={regNum}
                      onChange={handleChangeRegNum}
                      name="company-registration-number"
                      id="company-registration-number"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Contact number
                    </label>
                    <input
                      type="text"
                      name="contact-number"
                      id="contact-number"
                      onChange={handleChangeContactNum}
                      value={contactNum}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-registration-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company Email
                    </label>
                    <input
                      type="text"
                      onChange={handleChangeCompanyEmail}
                      value={companyEmail}
                      name="company-email"
                      id="company-email"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Incorporation date
                    </label>
                    <input
                      type="date"
                      name="Incorporation-date"
                      id="Incorporation-date"
                      value={incorpDate}
                      onChange={handleChangeIncorpDate}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-registration-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Incorporation type
                    </label>
                    <select
                      type="text"
                      name="company-incorp-type"
                      id="company-incorp-type"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={incorpType}
                      onChange={handleChangeIncorpType}
                    >
                      <option>Sole proprietorship</option>
                      <option>Partnership</option>
                      <option>Limited Liability Partnership (LLP)</option>
                      <option>Sendirian Berhad (Sdn Bhd)</option>
                      <option>Berhad (Bhd)</option>
                      <option>Other</option>
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Paid up capital
                    </label>
                    <input
                      type="text"
                      name="Incorporation-date"
                      id="Incorporation-date"
                      onChange={handleChangePaidUpCap}
                      value={paidUpCap}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Fiscal Year
                    </label>
                    <select
                      type="text"
                      name="fiscal-year"
                      id="book_closing_month"
                      value={fiscalYear}
                      onChange={handleChangeFiscalYear}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    >
                      {month.map((month, index) => (
                        <option key={index}>{month}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Website
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        http://
                      </span>
                      <input
                        type="text"
                        value={compWebsite}
                        onChange={handleChangeCompWebsite}
                        name="website"
                        id="website"
                        className="focus:ring-amber-500 focus:border-amber-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                        placeholder="example.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Company Icon (max 2MB)
                      </label>
                      <div className="mt-1 flex items-center space-x-5">
                        <img
                          src={companyIcon}
                          alt={companyName}
                          className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100"
                        />

                        <button
                          type="button"
                          className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                          onClick={handleClickIconUpload}
                        >
                          Change
                          <input
                            id="icon-upload"
                            ref={inputRef}
                            onChange={handleChangeIconUpload}
                            type="file"
                            accept="image/*"
                            className="sr-only"
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="company-registration-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Industry
                    </label>
                    <input
                      type="text"
                      defaultValue={compIndustry}
                      onChange={handleChangeCompIndustry}
                      name="company-industry"
                      id="company-industry"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company description
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows={4}
                      className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="Company A is aim to ..."
                      value={compDesc}
                      onChange={handleChangeCompDesc}
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Brief description for the company
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/*Financial info*/}
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Financial Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Basic information about the Company.
                </p>
              </div>
              <div className="mt-5 space-y-6 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="bank-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Bank name
                    </label>
                    <input
                      type="text"
                      name="bank-name"
                      id="bank-name"
                      onChange={handleChangeBankName}
                      value={compBankName}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company-registration-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Bank account number
                    </label>
                    <input
                      type="text"
                      onChange={handleChangeBankAccNum}
                      value={bankAccNum}
                      name="bank-account-number"
                      id="bank-account-number"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="bank-signing-condition"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Bank signing condition
                    </label>
                    <input
                      type="text"
                      name="bank-signing-condition"
                      id="bank-signing-condition"
                      value={bankSignCon}
                      onChange={handleChangeBankSignCon}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="auditor-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Auditor name
                    </label>
                    <input
                      type="text"
                      name="auditor-name"
                      id="auditor-name"
                      value={auditorName}
                      onChange={handleChangeAuditorName}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="auditor-link"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Auditor link
                    </label>
                    <input
                      type="text"
                      value={auditorLink}
                      name="auditor-link"
                      onChange={handleChangeAuditorLink}
                      id="auditor-link"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="tax-agent-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Tax agent name
                    </label>
                    <input
                      type="text"
                      name="tax-agent-name"
                      id="tax-agent-name"
                      value={taxAgent}
                      onChange={handleChangeTaxAgent}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="tax-agent-link"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Tax agent link
                    </label>
                    <input
                      type="text"
                      onChange={handleChangeTaxAgentLink}
                      value={taxAgentLink}
                      name="tax-agent-link"
                      id="tax-agent-link"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Government Agency Info*/}
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Government Agency Info
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Registered Accounts at Government Agency
                </p>
              </div>
              <div className="mt-5 space-y-6 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="sales_tax_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      SST Number
                    </label>
                    <input
                      type="text"
                      name="sales_tax_num"
                      id="sales_tax_num"
                      onChange={handleChangeSalesTax}
                      value={salesTax}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="employer_iden_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Employer number
                    </label>
                    <input
                      type="text"
                      value={empIden}
                      onChange={handleChangeEmpIden}
                      name="employer_iden_num"
                      id="employer_iden_num"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="income_tax_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Income Tax Number
                    </label>
                    <input
                      type="text"
                      value={incomeTax}
                      name="income_tax_num"
                      id="income_tax_num"
                      onChange={handleChangeIncomeTax}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="my_kwsp_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      KWSP
                    </label>
                    <input
                      type="text"
                      value={kwspNum}
                      onChange={handleChangeKwspNum}
                      name="my_kwsp_num"
                      id="my_kwsp_num"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="my_mof_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      MOF
                    </label>
                    <input
                      type="text"
                      name="my_mof_num"
                      id="my_mof_num"
                      onChange={handleChangeMofNum}
                      value={mofNum}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="my_miti_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      MITI
                    </label>
                    <input
                      type="text"
                      value={mitiNum}
                      onChange={handleChangeMitiNum}
                      name="my_miti_num"
                      id="my_miti_num"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="my_mbpp_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      MBPP
                    </label>
                    <input
                      type="text"
                      name="my_mbpp_num"
                      id="my_mbpp_num"
                      value={mbppNum}
                      onChange={handleChangeMbppNum}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="my_dbkl_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      DBKL
                    </label>
                    <input
                      type="text"
                      value={dbklNum}
                      onChange={handleChangeDbklNum}
                      name="my_dbkl_num"
                      id="my_dbkl_num"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="my_matrade_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      MATRADE
                    </label>
                    <input
                      type="text"
                      name="my_matrade_num"
                      id="my_matrade_num"
                      value={matradeNum}
                      onChange={handleChangeMatradeNum}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="duns_num"
                      className="block text-sm font-medium text-gray-700"
                    >
                      DUNS
                    </label>
                    <input
                      type="text"
                      value={dunsNum}
                      onChange={handleChangeDunsNum}
                      name="duns_num"
                      id="duns_num"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Reg Address*/}
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Registered Address
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Official address of an incorporated company, association or
                  any other legal entity.
                </p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <input
                      type="text"
                      id="country"
                      name="country"
                      onChange={handleChangeRegAddressCountry}
                      value={regAddressCountry}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                    ></input>
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Street address
                    </label>
                    <input
                      type="text"
                      onChange={handleChangeRegAddressAdd}
                      value={regAddressAdd}
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      value={regAddressCity}
                      onChange={handleChangeRegAddressCity}
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium text-gray-700"
                    >
                      State / Province
                    </label>
                    <input
                      value={regAddressState}
                      onChange={handleChangeRegAddressState}
                      type="text"
                      name="region"
                      id="region"
                      autoComplete="address-level1"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium text-gray-700"
                    >
                      ZIP / Postal code
                    </label>
                    <input
                      type="text"
                      value={regAddressPostal}
                      onChange={handleChangeRegAddressPostal}
                      name="postal-code"
                      id="postal-code"
                      autoComplete="postal-code"
                      className="mt-1 focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Business Address*/}
          {bizElement}

          <div className="flex justify-start space-x-4 -mt-20 -pt-20">
            <button
              type="button"
              className=" bg-red-600 py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-100 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={handleClickDeleteBizAddress}
            >
              Delete business location
            </button>
            <button
              type="button"
              className=" bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
              onClick={handleClickAddBizAddress}
            >
              Add another business location
            </button>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => reset()}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Reset
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Submit Changes
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
