import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { Billing } from "../../../DataSource/Billing";
import { useNavigate } from "react-router-dom";
import Body_Orders_Detail_Body from "./Body_Orders_Detail_Body";
import { HomeIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import {Compsec} from "../../../DataSource/Compsec";

const [companySecretary] = Compsec;
const pages = [
  { name: "Dashboard", href: "/dashboard", current: false },
  { name: "Orders", href: "/dashboard/orders", current: false },
  { name: "Detail", href: "#", current: true },
];

export default function OrderDetail({ selectedCompany }) {
  const navigate = useNavigate();
  const { orderID } = useParams();
  const [billDetail] = Billing.filter(
    // give you detail about the bill based on the url
    (bill) =>
      bill.payer_id === selectedCompany.company_id &&
      bill.id === Number(orderID)
  );
  if (billDetail === undefined) navigate("/dashboard/orders");

  useEffect(() => {
    if (billDetail === undefined) navigate("/dashboard/orders");
  }, []);

  return (
    <>
      {/* Header */}
      <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8 pb-6 md:flex">
        <nav className="flex" aria-label="Breadcrumb">
          <ol
            role="list"
            className="bg-white rounded-md shadow px-6 flex space-x-4"
          >
            <li className="flex">
              <div className="flex items-center">
                <Link to="/dashboard" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            <span className="sr-only text-amber-500 text-gray-500" />
            {pages.map((page) => (
              <li key={page.name} className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link
                    to={page.href}
                    className={`ml-4 text-sm font-${
                      page.current ? "bold" : "medium"
                    } text-${page.current ? "amber" : "gray"}-500 ${
                      page.current ? "" : "hover:text-gray-700"
                    }`}
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            onClick={() => navigate("/dashboard/request-service")}
          >
            Request Service
          </button>
        </div>
        {/*Recent activity*/}
      </div>
      {billDetail === undefined ? (
        navigate("/dashboard/orders")
      ) : (
        <Body_Orders_Detail_Body
          billDetail={billDetail}
          selectedCompany={selectedCompany}
          companySecretary={companySecretary}
        />
      )}
    </>
  );
}
