import { Body_Tabs } from "./Body_Tabs";
import { Body_BasicInfo } from "./Body_BasicInfo";
import { Body_SideBar } from "./Body_SideBar";
import { Route, Routes } from "react-router-dom";
import { Body_Structure } from "./Body_Structure";
import { Body_Shareholding } from "./Body_Shareholding";

export default function Body({ selectedCompany, companySecretary }) {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
      {/* Left 2 x Column Content*/}
      {/* The following design to be like tab content using the routing way */}
      <div className="space-y-6 lg:col-start-1 lg:col-span-2">
        <Body_Tabs />
        <Routes>
          <Route
            index
            element={<Body_BasicInfo selectedCompany={selectedCompany} />}
          />
          <Route
            path="company"
            element={<Body_BasicInfo selectedCompany={selectedCompany} />}
          />
          <Route
            path="structure"
            element={<Body_Structure selectedCompany={selectedCompany} />}
          />
          <Route
            path="shareholding"
            element={<Body_Shareholding selectedCompany={selectedCompany} />}
          />
        </Routes>
      </div>

      {/* Right 1 x Column Content*/}
      <Body_SideBar
        CompSec={companySecretary}
        selectedCompany={selectedCompany}
      />
    </div>
  );
}
