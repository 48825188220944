import {
  FilterIcon,
  QuestionMarkCircleIcon,
  TemplateIcon,
  LogoutIcon,
  CurrencyDollarIcon,
  LibraryIcon,
} from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";

// Apps Navigation (After Login)
// Current = true is the initial highlight
const Navigation = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: TemplateIcon,
    current: true,
  },
  // {
  //   name: "E-Learning",
  //   href: "/elearning",
  //   icon: BookOpenIcon,
  //   current: false,
  // },
  {
    name: "Resume Classifier",
    href: "/resume-filtering",
    icon: FilterIcon,
    current: false,
  },
  // {
  //   name: "Recruitment",
  //   href: "/recruitment",
  //   icon: SpeakerphoneIcon,
  //   current: false,
  // },
  // {
  //   name: "HR Operation",
  //   href: "/hr-operation",
  //   icon: UserGroupIcon,
  //   current: false,
  // },
  {
    name: "Company Secretary",
    href: "/company-secretary",
    icon: LibraryIcon,
    current: false,
  },
  // {
  //   name: "Investor Portal",
  //   href: "/investor",
  //   icon: DocumentReportIcon,
  //   current: false,
  // },
  // edit or add more
];

// Apps Secondary Navigation (After Login, under the main Navigation)
const secondaryNavigation = [
  { name: "Billing", href: "/billing", icon: CurrencyDollarIcon },
  // { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "/help", icon: QuestionMarkCircleIcon },
  // edit or add more d
];

const logOut = {
  name: "Log out",
  href: "/",
  icon: LogoutIcon,
  color: "white",
};

// const checkCurrentMenu = function () {
//   const path = window.location.pathname;
//   Navigation.map((e) => (path === e.href ? true : false));
// };

// Function for Desktop Menu
export function NavigationDesktop({ LoginUser }) {
  // checkCurrentMenu();
  return (
    <>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow bg-cyan-700 pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img
              className="h-8 w-auto"
              src="/assets/logo-full-white.svg"
              alt="Nexent Backoffice"
            />
          </div>
          <nav
            className="mt-5 flex-1 flex flex-col divide-y divide-cyan-800 overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="px-2 space-y-1">
              {Navigation.map((item, index) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-cyan-800 text-white group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                      : "text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  }
                  aria-current={({ isActive }) =>
                    isActive ? "page" : undefined
                  }
                >
                  <item.icon
                    className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200"
                    aria-hidden="true"
                  />
                  {item.name}
                </NavLink>
              ))}
            </div>
            <div className="mt-6 pt-6">
              <div className="px-2 space-y-1">
                {secondaryNavigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      isActive
                        ? "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white bg-cyan-800"
                        : "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
                    }
                  >
                    <item.icon
                      className="mr-4 h-6 w-6 text-cyan-200"
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))}
                <a
                  href={logOut.href}
                  // TODO To remove the session token
                  onClick={() => localStorage.removeItem("token")}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium font-bold rounded-md text-${logOut.color} hover:text-white hover:bg-cyan-600`}
                >
                  <logOut.icon
                    className={`mr-4 h-6 w-6 text-${logOut.color}`}
                    aria-hidden="true"
                  />
                  {logOut.name}
                </a>
              </div>
            </div>
          </nav>
        </div>
        <div className="flex-shrink-0 flex border-t border-cyan-800 bg-cyan-700 p-4">
          <a href="#" className="flex-shrink-0 w-full group block">
            <div className="flex items-center">
              <div>
                <img
                  className="inline-block h-9 w-9 rounded-full"
                  src={LoginUser.user_avatar}
                  alt=""
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-white">
                  {LoginUser.name}
                </p>
                <p className="text-xs font-medium text-cyan-200 group-hover:text-white">
                  View profile
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}

// Function for Mobile Menu
export function NavigationMobile() {
  // checkCurrentMenu();
  return (
    <>
      <div className="px-2 space-y-1">
        {Navigation.map((item, index) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              isActive
                ? "bg-cyan-800 text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"
                : "text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-base font-medium rounded-md"
            }
            aria-current={item.current ? "page" : undefined}
          >
            <item.icon
              className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200"
              aria-hidden="true"
            />
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="mt-6 pt-6">
        <div className="px-2 space-y-1">
          {secondaryNavigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) =>
                isActive
                  ? "bg-cyan-800 text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"
                  : "text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-base font-medium rounded-md"
              }
            >
              <item.icon
                className="mr-4 h-6 w-6 text-cyan-200"
                aria-hidden="true"
              />
              {item.name}
            </NavLink>
          ))}
          <a
            href={logOut.href}
            className={`group flex items-center px-2 py-2 text-base font-medium rounded-md text-${logOut.color} hover:text-white hover:bg-cyan-600`}
          >
            <logOut.icon
              className={`mr-4 h-6 w-6 text-${logOut.color}`}
              aria-hidden="true"
            />
            {logOut.name}
          </a>
        </div>
      </div>
    </>
  );
}
