import "./App.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { NavigationDesktop } from "./Components/Navigation";
import { Page_upcoming } from "./Components/Page_upcoming";
import { LoginUser } from "./DataSource/LoginUser";
import { Companies } from "./DataSource/Companies";
import { Compsec } from "./DataSource/Compsec";
import CompSec from "./Pages/CompanySecretary/CompSec";

export default function App() {
  return (
    <div className="min-h-full">
      <NavigationDesktop LoginUser={LoginUser} />
      <Routes>
        <Route
          path="dashboard/*"
          element={
            <Dashboard
              LoginUser={LoginUser}
              Companies={Companies}
              Compsecs={Compsec}
            />
          }
        />
        <Route
          path="elearning"
          element={<Page_upcoming LoginUser={LoginUser} />}
        />
        <Route
          path="resume-filtering"
          element={<Page_upcoming LoginUser={LoginUser} />}
        />
        <Route
          path="recruitment"
          element={<Page_upcoming LoginUser={LoginUser} />}
        />
        <Route
          path="hr-operation"
          element={<Page_upcoming LoginUser={LoginUser} />}
        />
        <Route
          path="company-secretary/*"
          element={<CompSec LoginUser={LoginUser} CompSecs={Compsec} />}
        />
        <Route
          path="investor"
          element={<Page_upcoming LoginUser={LoginUser} />}
        />
        <Route
          path="billing"
          element={<Page_upcoming LoginUser={LoginUser} />}
        />
        <Route path="help" element={<Page_upcoming LoginUser={LoginUser} />} />
      </Routes>
    </div>
  );
}
