export function Body_BasicInfo_GovAgency({ selectedCompany }) {
  // Company Government Agency Related Account

  const GovernanceAgencyInfo = [
    {
      what: "SST Number",
      answer: selectedCompany.sales_tax_num,
    },
    {
      what: "Employer Number",
      answer: selectedCompany.employer_iden_num,
    },
    {
      what: "Income Tax Number",
      answer: selectedCompany.income_tax_num,
    },
    {
      what: "KWSP",
      answer: selectedCompany.my_kwsp_num,
    },
    {
      what: "MOF",
      answer: selectedCompany.my_mof_num,
    },
    {
      what: "MITI",
      answer: selectedCompany.my_miti_num,
    },
    {
      what: "MBPP",
      answer: selectedCompany.my_mbpp_num,
    },
    {
      what: "DBKL",
      answer: selectedCompany.my_dbkl_num,
    },
    {
      what: "MATRADE",
      answer: selectedCompany.my_matrade_num,
    },
    {
      what: "DUNS",
      answer: selectedCompany.duns_num,
    },
  ];

  return (
    // Company Government Agency Related Account

    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6  border-b-amber-400 border-b">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Government Agency Info
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Registered Accounts at Government Agency
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          {GovernanceAgencyInfo.map((info, index) =>
            index % 2 > 0 ? (
              <div
                key={index}
                className="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              >
                <dt className="text-sm font-medium text-gray-500">
                  {info.what}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {info.answer}
                </dd>
              </div>
            ) : (
              <div
                key={index}
                className="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              >
                <dt className="text-sm font-medium text-gray-500">
                  {info.what}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {info.answer}
                </dd>
              </div>
            )
          )}
        </dl>
      </div>
    </div>
  );
}
