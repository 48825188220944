// TODO Update the Companies by fetching the data from database instead
// This is eventually same as Companies.js in Datasource, making it specifically for CompSec
// lookup Company Secretary and load the companies here (array)
// This CompSec_Companies is based on Compsec.js

export const CompSec_Companies = [
  {
    company_id: "1",
    name: "Nexent Sdn Bhd",
    comp_logo: "/assets/logo-black.svg",
    reg_number: "9871239872",
    incorp_date: "2022-01-19",
    incorp_type: "Sendirian Berhad (Sdn Bhd)",
    industry: "Consultant",
    description:
      "Nexent aims to be the next innovative business process automation provider, specialising in Customer Experience solutions and Operational Excellence initiatives, utilizing advanced data analytics and business process redesign to deliver results.",
    email: "hello@nexent.co",
    website: "nexent.co",
    contact_number: "04-1234567",
    reg_address: {
      //from other table
      address: "123 Street",
      city: "Georgetown",
      state: "Penang",
      postal: "11700",
      country: "Malaysia",
    },
    biz_address: [
      {
        name: "HQ",
        address: "234 Biz Street",
        city: "KLCC",
        state: "Kuala Lumpur",
        postal: "58100",
        country: "Malaysia",
      },
      {
        name: "KL Branch",
        address: "234 Biz Street",
        city: "KLCC",
        state: "Kuala Lumpur",
        postal: "58100",
        country: "Malaysia",
      },
    ],
    bank_name: "HSBC Bank",
    bank_acc_num: "298712312321",
    bank_sign_condition: "A+B",
    status: { status: "Good Standing", color: "green" },
    paid_up_capital: "RM 5,000,000",
    book_closing_month: "April", // selection of month
    registration_expiration: "23-12-2025",
    compsec_id: "1",
    auditor_name: "Auditor XYZ Sdn Bhd",
    auditor_link: "https://nexent.co",
    tax_agent_name: "SuperTax Agent Sdn Bhd",
    tax_agent_link: "https://nexent.co",
    sales_tax_num: "654685135",
    employer_iden_num: "98274323423",
    income_tax_num: "DDS98978",
    my_kwsp_num: "2938423234",
    my_mof_num: "53443563323",
    my_miti_num: "23423643",
    my_mbpp_num: "g43t3434",
    my_dbkl_num: "345ge3t4",
    my_matrade_num: "345343f3",
    duns_num: "345344g755",
  },
  {
    company_id: "2",
    name: "Xpress AI",
    comp_logo: "/assets/xpress.svg",
    reg_number: "987287239872",
    incorp_date: "2011-09-08",
    incorp_type: "Berhad (Bhd)",
    industry: "Technology",
    description:
      "Certified by Bank Negara, our solutions represent the safest choice for eKYC compliance in Malaysia. Even if your organization is not required to follow regulations from Bank Negara, our ID Verification is able to meet your needs in the financial sector.",
    email: "eduardo@xpress.ai",
    website: "xpress.co",
    contact_number: "04-1234567",
    reg_address: {
      //only one because it is for gov ussage
      address: "123 Jp Street",
      city: "Tokyo City",
      state: "Tokyo",
      postal: "22231",
      country: "Japan",
    },
    biz_address: [
      {
        // can have multiple in case have branchs
        name: "HQ",
        address: "123 JPY Street",
        city: "Kyoto",
        state: "Hokkaido",
        postal: "728282",
        country: "Japan",
      },
    ],
    bank_name: "MUFG Bank",
    bank_acc_num: "8762923432",
    bank_sign_condition: "A only",
    status: { status: "Pending Audit", color: "orange" }, // green, orange, red
    paid_up_capital: "RM 1000",
    book_closing_month: "December",
    registration_expiration: "23-11-2030",
    compsec_id: "1",
    auditor_name: "Xpress Auditor",
    auditor_link: "https://xpress.ai",
    tax_agent_name: "Xpress Tax agent",
    tax_agent_link: "https://xpress.ai",
    sales_tax_num: "98298723432",
    employer_iden_num: "876239487",
    income_tax_num: "464efwf2we",
    my_kwsp_num: "34rd4d",
    my_mof_num: "34534534",
    my_miti_num: "34534ff",
    my_mbpp_num: "345wfd34r34",
    my_dbkl_num: "3223423vevcrc",
    my_matrade_num: "345fd4",
    duns_num: "453423423",
  },
];
