import {
  CashIcon,
  DocumentTextIcon,
  UserAddIcon,
} from "@heroicons/react/outline";
import { MailIcon, HomeIcon, PhoneIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { pendingActivities } from "../../../DataSource/Activities";

export function Body_SideBar({ CompSec, selectedCompany }) {
  const selectedCompanyActivities = pendingActivities.filter(
    (item) => item.company_id === selectedCompany.company_id
  );

  const topFivePendingActivities = selectedCompanyActivities.filter(
    (_item, index) => index <= 5
  );

  function typeIcon(type) {
    let icon = UserAddIcon;
    if (type === "doc") icon = DocumentTextIcon;
    if (type === "bill") icon = CashIcon;

    return icon;
  }

  const activities = topFivePendingActivities.map((item) => ({
    ...item,
    icon: typeIcon(item.type),
  }));

  return (
    <div className="lg:col-start-3 lg:col-span-1">
      <div className="sticky top-4 space-y-4">
        {/* Right 1 x Column Content - Content*/}
        {/* COMPSEC*/}
        <section aria-labelledby="Upcoming Activities">
          <div className="bg-white rounded-lg shadow">
            <div className="p-6">
              <h2
                id="Company Secretary"
                className="text-base font-medium text-gray-900"
              >
                Company Secretary
              </h2>
              <div className="mt-6 flow-root">
                <div className="flex items-center pt-0 pb-6 space-x-3">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={CompSec.logo}
                      alt={CompSec.name}
                    />
                  </div>
                  <div className="min-w-0 flex-1 text-sm font-medium text-gray-900 grid grid-rows-2">
                    <div className="row-span-1">{CompSec.name}</div>
                    <div className="row-span-1 text-gray-600 text-sm grid-flow-col">
                      {CompSec.compsec_number}
                    </div>
                  </div>
                </div>
                <div className=" space-x-3">
                  <ul
                    role="list"
                    className="-my-4 divide-y-4 divide-transparent pl-10 pr-4 pb-3"
                  >
                    <li>
                      <div className=" pl-2 flex">
                        <MailIcon
                          className="h-5 w-5 mr-2 flex-none text-gray-900"
                          aria-hidden="true"
                        />
                        <div className="text-sm text-gray-900 flex-shrink break-all">
                          {CompSec.email}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className=" pl-2 flex">
                        <PhoneIcon
                          className="h-5 w-5 mr-2 flex-none text-gray-900"
                          aria-hidden="true"
                        />
                        <div className="text-sm text-gray-900 flex-shrink">
                          {CompSec.contact_number}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className=" pl-2 flex">
                        <HomeIcon
                          className="h-5 w-5 mr-2 flex-none text-gray-900"
                          aria-hidden="true"
                        />
                        <div className="text-sm text-gray-900">
                          {CompSec.address.address}, {CompSec.address.city},{" "}
                          {CompSec.address.postal} {CompSec.address.state},{" "}
                          {CompSec.address.country}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-6">
                <Link
                  to="/dashboard/request-service"
                  className="w-full block text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Request Service
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* COMPSEC END*/}
        {/* REQUIRED ATTENTION*/}
        <section aria-labelledby="Required Attention">
          <div className="rounded-lg bg-white overflow-hidden shadow">
            <div className="p-6">
              <h2
                id="trending-heading"
                className="text-base font-medium text-gray-900"
              >
                Required Attention
              </h2>
              <div className="mt-6 flow-root">
                <ul role="list" className="-my-5 divide-y divide-gray-200">
                  {activities.map((activity, index) => (
                    <li key={index} className="py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <div className="sr-only text-orange-500 text-red-500 ">
                            Pre-Generated Color: yellow, red
                          </div>
                          <activity.icon
                            className={`h-8 w-8 rounded-full text-${activity.status.color}-500`}
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900">
                            {activity.desc}
                          </p>
                          <p
                            // TODO IF TO apply Color other than amber, blue, red etc, need to apply static color first
                            className={`text-sm text-${activity.status.color}-500 truncate`}
                          >
                            Due on {activity.date}
                          </p>
                        </div>
                        <div>
                          {activity.type === "doc" ? (
                            <a
                              href={activity.link}
                              target="_blank"
                              className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                            >
                              View
                            </a>
                          ) : (
                            <Link
                              to={activity.link}
                              className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                            >
                              View
                            </Link>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-6">
                <Link
                  to="/dashboard/activities"
                  className="w-full block text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  View all
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* REQUIRED ATTENTION END*/}
        {/* END Right 1 x Column Content - Content*/}
      </div>
    </div>
  );
}
