import {
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";
import { useEffect, useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function CompSec_Body_ManageCompany_Header_CompanySelection({
  SelectedCompany,
  SetSelectCompany,
  Companies,
}) {
  const [query, setQuery] = useState("");
  const [theCompany, setTheCompany] = useState(Companies[0]);
  const filteredCompanies =
    query === ""
      ? Companies
      : Companies.filter((company) =>
          company.name.toLowerCase().includes(query.toLowerCase())
        );

  const changeCompany = (company) => {
    SetSelectCompany(company);
    setTheCompany(company);
  };

  return (
    <>
      <div className="flex-1 min-w-0">
        {/* Company Profile */}
        <div className="flex items-center">
          <div>
            <div className="flex items-center">
              {Companies.length === 1 ? (
                <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                  {SelectedCompany.name}
                </h1>
              ) : (
                <Combobox as="div" value={theCompany} onChange={changeCompany}>
                  <div className="relative mt-1">
                    <Combobox.Input
                      // TODO Implementing the function to change font/width based on Company's name length
                      // change w-60 and also sm:text-2xl based on Company Name Length
                      className="rounded-md border-0 border-transparent bg-white pl-2 pr-4 sm:text-2xl font-bold w-60"
                      onChange={(event) => setQuery(event.target.value)}
                      displayValue={(company) => company?.name}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>

                    {filteredCompanies.length > 0 && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredCompanies.map((company) => (
                          <Combobox.Option
                            key={company.company_id}
                            value={company}
                            className={({ active }) =>
                              classNames(
                                "relative cursor-default select-none py-2 pl-3 pr-2",
                                active
                                  ? "bg-cyan-600 text-white"
                                  : "text-gray-900"
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <span
                                  className={classNames(
                                    "block truncate",
                                    selected && "font-semibold"
                                  )}
                                >
                                  {company.name}
                                </span>

                                {selected && (
                                  <span
                                    className={classNames(
                                      "absolute inset-y-0 right-0 flex items-center pr-2",
                                      active ? "text-white" : "text-cyan-600"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
